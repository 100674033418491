.leaderboardTournamentStatusDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 24px;
  padding: 4px 24px;

  text-align: center;

  background: #17181f;

  border: solid 1px transparent;

  border-radius: 24px;

  &.sizeLarge {
    align-self: flex-start;

    padding: 6px 10px;

    font-size: var(--font-size-small);
    font-weight: 600;
  }

  &.sizeSmall {
    flex: none;

    flex-grow: 0;

    order: 0;
    max-width: 150px;

    font-size: var(--font-size-smaller);
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-align: right;
  }

  &.FINISHED {
    color: var(--color-gray3);
  }

  &.FUTURE {
    color: var(--color-green);
  }

  &.IN_PROGRESS {
    color: var(--color-orange);
  }

  &.IN_REVIEW {
    color: var(--color-yellow);
  }

  &.PAUSED {
    color: var(--color-red);
  }
}
