.callToAction {
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    padding: 48px 20px 68px;

    text-align: center;

    .title {
      font-size: var(--font-size-headline3);
      font-weight: 700;
    }

    .content {
      max-width: 640px;

      font-size: var(--font-size-small);
      font-weight: 500;

      color: var(--color-gray);
    }

    .button {
      margin-top: 22px;
    }
  }
}

@media (--min-sm) {
  .callToAction {
    .container {
      .button {
        width: 180px;
      }
    }
  }
}

@media (--min-md) {
  .callToAction {
    .container {
      .title {
        font-size: var(--font-size-headline1);
      }
    }
  }
}
