.onlineUser {
  display: grid;

  grid-template-columns: repeat(2, min-content);
  grid-gap: 10px;

  justify-content: space-between;

  .user {
    display: flex;

    flex-direction: row;

    gap: 10px;

    align-items: center;

    color: var(--color-white);
    text-decoration: none;

    transition: transform 150ms ease;

    &[data-focus-visible-added],
    &:hover {
      transform: scale(1.2);
    }

    .username {
      font-size: var(--font-size-small);
      font-weight: 700;
      color: var(--color-white);
    }
  }

  .action {
    justify-self: flex-end;
  }
}
