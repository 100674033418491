.emptyStateDisplay {
  display: grid;
  align-items: center;
  justify-content: center;

  grid-gap: 24px;

  .icon {
    display: flex;

    align-items: center;
    justify-content: center;

    svg {
      display: block;

      width: 200px;
      max-width: 100%;
      height: auto;
    }
  }

  .iconBackground {
    padding: 15px 10px;

    background-color: #1c1e26;

    border-radius: 25px;
  }

  .message {
    font-size: var(--font-size-small);
    font-weight: 600;
    text-align: center;

    color: var(--color-gray);
  }
}
