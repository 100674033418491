[dir='rtl'] {
  .duelCardList {
    .header {
      .viewAll {
        margin-right: 50px;

        text-align: left;
      }
    }
  }
}

[dir='ltr'] {
  .duelCardList {
    .header {
      .viewAll {
        margin-left: 50px;

        text-align: right;
      }
    }
  }
}

.filterBtn {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: 3px;

  transform: translateY(-50%);
}

.duelCardList {
  &.display-2 {
    .cards {
      display: grid;
      flex-wrap: wrap;
    }

    .showMoreButtonWrapper {
      grid-template-columns: repeat(3, 1fr);

      .submit {
        grid-column: 1 span / -2;
      }
    }
  }

  .header {
    position: relative;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 30px;

    font-size: var(--font-size);
    font-weight: 700;

    .duelCount {
      font-weight: 500;

      color: var(--color-gray);
    }

    .viewAll {
      font-size: var(--font-size-small);
      font-weight: 500;
      text-decoration: none;

      color: var(--color-gray);

      &[data-focus-visible-added],
      &:hover {
        text-decoration: underline;
      }
    }

    h3 {
      font-size: var(--font-size);
    }
  }

  .cards {
    display: grid;
    flex-wrap: wrap;
    grid-gap: 24px 32px;
  }

  .showMoreButtonWrapper {
    display: grid;

    margin-top: 36px;

    .submit {
      grid-column: 1 span;
    }
  }
}

@media (--min-sm) {
  .duelCardList {
    .cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (--min-xl) {
  .duelCardList {
    .cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (min-width: 1810px) {
  .duelCardList {
    .cards {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media (min-width: 2260px) {
  .duelCardList {
    .cards {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
