[dir='rtl'] {
  .boxLabel {
    right: var(--form-component-horizontal-padding);

    margin-right: 0px;
  }
}

[dir='ltr'] {
  .boxLabel {
    left: var(--form-component-horizontal-padding);

    margin-left: 0px;
  }
}

.boxLabel {
  position: absolute;
  z-index: 1;
  top: 50%;

  font-size: var(--font-size-small);
  font-weight: 500;

  pointer-events: none;

  color: var(--color-gray3);

  transition: top 150ms ease, transform 150ms ease, font-size 150ms ease;

  transform: translateY(-50%);

  /* sizes */
  &.sizeXlarge {
    &.onTop {
      top: 8px;
    }
  }

  &.sizeLarge {
    font-size: var(--font-size-small);

    &.onTop {
      top: 7px;
    }
  }

  &.sizeMedium {
    font-size: var(--font-size-small);

    &.onTop {
      top: 5px;
    }
  }

  &.sizeSmall {
    font-size: var(--font-size-small);

    &.onTop {
      top: 2px;
    }
  }

  &.onTop {
    top: 4px;

    font-size: var(--font-size-smaller);
    font-weight: 600;

    color: var(--color-gray);

    transform: translateY(0);
  }
}

@media only screen and (max-width: 375px) {
  .boxLabel {
    font-size: var(--font-size-tiny);
  }
}