[dir='rtl'] {
  .ladderTournamentCardList {
    .header {
      .viewAll {
        margin-right: 50px;

        text-align: left;
      }
    }
  }
}

[dir='ltr'] {
  .ladderTournamentCardList {
    .header {
      .viewAll {
        margin-left: 50px;

        text-align: right;
      }
    }
  }
}

.ladderTournamentCardList {
  .header {
    display: flex;

    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;

    .tournamentCount {
      font-weight: 500;

      color: var(--color-gray);
    }

    .viewAll {
      font-size: var(--font-size-small);
      font-weight: 500;
      text-decoration: none;

      color: var(--color-gray);

      &[data-focus-visible-added],
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .list {
    display: grid;
    flex-wrap: wrap;
    grid-gap: 32px;
  }

  .showMoreButtonWrapper {
    display: flex;

    justify-content: center;

    margin: 36px auto;
  }
}

@media (--min-sm) {
  .ladderTournamentCardList {
    .list {
      grid-template-columns: repeat(2, 1fr);
    }

    .showMoreButtonWrapper {
      max-width: 50%;
    }
  }
}

@media (--min-xl) {
  .ladderTournamentCardList {
    .list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (min-width: 1810px) {
  .ladderTournamentCardList {
    .list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media (min-width: 2260px) {
  .ladderTournamentCardList {
    .list {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
