.ourProductMobile {
  display: flex;
  align-items: center; 
  flex-direction: column;
  padding: 55px 20px 64px;

  h3 {
    margin-bottom: 30px;

    font-size: var(--font-size-headline3);
    font-weight: 700;
    text-align: center;
  }

  img {
    max-width: 100%;
    margin-bottom: 32px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
  }
}

@media (--min-lg  ) {
  .ourProductMobile {
    padding: 55px 112px 156px;

    img {
      max-width: 65%;
      margin-bottom: 32px;
    }

    h3 {
      margin-bottom: 64px;

      font-size: 48px;
    }

    .cards {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 26px;
    }

  }
}