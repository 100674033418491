[dir='rtl'] {
  .notificationMenu {
    .notificationIndicator {
      left: 30%;
    }

    .box {
      left: 5vw;
    }
  }
}

[dir='ltr'] {
  .notificationMenu {
    .notificationIndicator {
      right: 30%;
    }

    .box {
      right: 5vw;
    }
  }
}

.notificationMenu {
  position: relative;

  .headerButton {
    svg {
      display: block;

      width: 16px;
      height: 20px;
    }
  }

  .notificationIndicator {
    position: absolute;
    z-index: 2;
    top: 16%;

    width: 10px;
    height: 10px;

    font-size: 8px;
    font-weight: 700;
    text-align: center;

    background-color: var(--color-red);

    border-radius: 50%;

    transform: translate(50%, 50%);
  }

  .box {
    position: fixed;
    z-index: 11;

    top: 80px;

    display: flex;

    flex-direction: column;

    width: 90vw;
    max-height: calc(80vh - 80px);

    transition: opacity 300ms ease, transform 300ms ease;

    &:global {
      &.enter,
      &.exit.exit-active {
        opacity: 0;

        transform: translateY(-20px);
      }

      &.enter.enter-active,
      &.exit {
        opacity: 1;

        transform: translateY(0);
      }
    }

    .header {
      display: flex;

      align-items: center;
      justify-content: space-between;

      flex-direction: row;

      padding: 10px 16px;

      background-color: var(--color-dark-gray);

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .title {
        font-size: var(--font-size-big);
        font-weight: 700;
      }

      .markAllAsReadButton {
        font-size: var(--font-size-small);
        font-weight: 700;
        text-decoration: none;

        cursor: pointer;

        color: var(--color-white);

        &[data-focus-visible-added],
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .body {
      flex: 1 1 auto;

      padding: 10px 16px;

      overflow: auto;

      background-color: var(--color-black3);
    }

    .footer {
      display: flex;

      justify-content: center;

      flex-direction: row;

      padding: 10px 16px;

      background-color: var(--color-dark-gray);

      border-bottom-right-radius: 8px;

      border-bottom-left-radius: 8px;

      .pageLink {
        font-size: var(--font-size-small);
        font-weight: 700;
        text-decoration: none;

        color: var(--color-white);

        &[data-focus-visible-added],
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (--min-md) {
  .notificationMenu {
    .box {
      width: 400px;
    }
  }
}
