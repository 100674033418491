.inputWrapper {
  position: relative;

  &.noLabel {
    input {
      padding: 0 var(--form-component-horizontal-padding);
    }
  }

  .input {
    display: block;

    width: 100%;

    font-weight: 500;

    background-color: var(--color-black2);

    color: var(--color-white);

    border: 1px solid var(--color-dark-gray2);
    border-radius: var(--form-component-border-radius);

    transition: border-color 150ms ease;

    caret-color: var(--color-red);

    &.withLabel {
      padding: 10px var(--form-component-horizontal-padding) 0 var(--form-component-horizontal-padding);
    }

    &:focus {
      border-color: var(--color-red);
    }

    &:disabled {
      cursor: not-allowed;

      background-color: var(--color-dark-gray2);
      color: var(--color-gray);

      border-color: var(--color-dark-gray3);
    }
  }

  /* size */
  &.sizeXlarge {
    .input {
      height: var(--form-component-xlarge-height);

      font-size: var(--font-size-small);
    }
  }

  &.sizeLarge {
    .input {
      height: var(--form-component-large-height);

      padding-top: 16px;

      font-size: var(--font-size-small);
    }
  }

  &.sizeMedium {
    .input {
      height: var(--form-component-medium-height);

      font-size: var(--font-size-small);
    }
  }

  &.sizeSmall {
    .input {
      height: var(--form-component-small-height);

      font-size: var(--font-size-smaller);
    }
  }

  &.hasError {
    .input {
      color: var(--color-red);

      border-color: var(--color-red);
    }
  }

  &:has(input:-webkit-autofill) label {
    top: 4px;

    font-size: var(--font-size-smaller);
    font-weight: 600;

    color: var(--color-gray);

    transform: translateY(0);
  }

  &.iconInput {
    .input {
      padding-right: 40px;
    }
  }

  .pwdIcon {
    position: absolute;
    top: 50%;
    right: 14px;

    transform: translateY(-50%);
  }
}