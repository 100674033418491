.pageHeader {
  display: flex;

  flex-direction: column;

  min-height: 400px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &.backgroundStatic {
    background-position: top center;
  }

  &.backgroundCouponRedemption {
    background-image: url('./redeem-coupon.jpg');
  }

  &.backgroundDuelsListing {
    background-image: url('./duels-listing.jpg');

    /* stylelint-disable-next-line plugin/selector-tag-no-without-class */
    & > div {
      background-image: linear-gradient(
        180deg,
        rgba(17, 18, 23, 0) 40.84%,
        #17181f 100%
      );
    }
  }

  &.backgroundGameStatistics {
    background-image: url('./password-reset.jpg');
  }

  &.backgroundPasswordRecovery {
    background-image: url('./password-recovery.jpg');
  }

  &.backgroundPasswordReset {
    background-image: url('./password-reset.jpg');
  }

  &.backgroundSettings {
    background-image: url('./settings.jpg');
  }

  &.backgroundWallet {
    background-image: url('./wallet.jpg');
  }

  .body {
    display: flex;
    align-items: center;

    justify-content: center;
    flex: 1;

    flex-direction: column;

    padding: 20px;

    text-align: center;

    background: linear-gradient(
      to bottom,
      rgba(17, 18, 23, 0.5),
      var(--color-black) 100%
    );

    .title {
      max-width: 510px;

      font-size: 20px;
    }

    .description {
      max-width: 510px;

      margin-top: 4px;

      font-size: var(--font-size);
      font-weight: 700;

      color: var(--color-gray);
    }
  }
}

@media (--min-lg) {
  .pageHeader {
    .body {
      .title {
        font-size: 32px;
      }

      .description {
        margin-top: 13px;
      }
    }
  }
}
