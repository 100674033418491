.filterButton {
  .icon {
    position: relative;

    .filledIndicator {
      position: absolute;
      top: 10%;
      right: 0;

      width: 15px;

      height: 15px;

      font-size: 10px;
      text-align: center;

      background-color: var(--color-red);

      border-radius: 50%;

      transform: translate(50%, -50%);
    }

    svg {
      display: block;

      width: 18px;
      height: 20px;

      fill: var(--color-gray4);
    }
  }
}
