.leaderboardTournamentInfo {
  background-color: var(--color-black3);

  border-radius: 8px;

  .header {
    padding: 12px 10px;

    font-weight: 700;

    background-color: var(--color-dark-gray);

    color: var(--color-white);

    border-radius: 8px 8px 0 0;

    transition: padding 150ms ease;
  }

  .body {
    display: grid;

    grid-gap: 16px;

    padding: 24px 10px;

    border-radius: 0 0 8px 8px;

    .info {
      display: grid;
      align-items: center;
      justify-content: flex-start;
      grid-template-columns: repeat(2, auto);
      grid-gap: 16px;

      padding: 16px;

      font-size: var(--font-size);
      font-weight: 600;

      background-color: var(--color-dark-gray);

      color: var(--color-white);

      border-radius: 8px;

      transition: padding 150ms ease;

      .infoWrapper {
        min-width: 90px;

        font-weight: 700;

        .muted {
          font-size: var(--font-size-smaller);

          color: var(--color-gray);
        }
      }

      .gaCoin {
        width: 24px;
        height: 24px;
        margin-top: 0px;
      }

      svg {
        display: block;
        width: 18px;
        height: 20px;
      }

      &.startsAt {
        svg {
          height: 22px;

          > * {
            stroke: #00c202;
          }
        }
      }

      &.endsAt {
        svg {
          height: 22px;

          > * {
            stroke: #ee112d;
          }
        }
      }
    }
  }
}

@media (--min-xs) {
  .leaderboardTournamentInfo {
    .header {
      padding: 12px 32px;
    }

    .body {
      padding: 24px;
    }
  }
}