.ladderTournamentStatusDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 24px;
  padding: 4px 24px;

  text-align: center;

  background: #17181f;

  border-radius: 24px;

  &.sizeLarge {
    align-self: flex-start;

    padding: 6px 10px;

    font-size: var(--font-size-small);
    font-weight: 600;

    border-radius: 15px;
  }

  &.sizeSmall {
    font-size: var(--font-size-smaller);
    font-weight: 600;
  }

  &.FINISHED {
    color: var(--color-gray);
  }

  &.REGISTRATION,
  &.FUTURE {
    color: var(--color-green);
  }

  &.IN_PROGRESS {
    color: var(--color-orange);
  }

  &.PAUSED {
    color: var(--color-red);
  }
}
