.textarea {
  position: relative;

  /* size */
  &.sizeLarge {
    .input {
      font-size: var(--font-size);
    }
  }

  &.sizeMedium {
    .input {
      font-size: var(--font-size-small);
    }
  }

  &.sizeSmall {
    .input {
      font-size: var(--font-size-smaller);
    }
  }

  &.hasError {
    .input {
      color: var(--color-red);

      border-color: var(--color-red);
    }
  }

  &.withLabel {
    .input {
      padding: 24px var(--form-component-horizontal-padding) 0
        var(--form-component-horizontal-padding);
    }
  }

  .input {
    display: block;

    width: 100%;
    min-height: 60px;

    padding: 14px var(--form-component-horizontal-padding) 0
      var(--form-component-horizontal-padding);

    font-weight: 600;
    line-height: 1.4;

    resize: vertical;

    background-color: var(--color-black2);

    color: var(--color-white);

    border: 1px solid var(--color-dark-gray2);
    border-radius: var(--form-component-border-radius);

    transition: border-color 150ms ease;

    &:focus {
      border-color: var(--color-red);
    }

    &:disabled {
      cursor: not-allowed;

      background-color: var(--color-dark-gray2);
    }
  }
}
