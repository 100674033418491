[dir='rtl'] {
  .inlineLabel {
    &.withImage {
      img {
        margin-left: 10px;

        &.imageRadius {
          margin-left: 8px;
        }
      }
    }
  }
}

[dir='ltr'] {
  .inlineLabel {
    &.withImage {
      img {
        margin-right: 10px;

        &.imageRadius {
          margin-right: 8px;
        }
      }
    }
  }
}

.inlineLabel {
  font-size: var(--font-size);
  font-weight: 600;

  cursor: pointer;

  &.sizeSmall {
    font-size: var(--font-size-smaller);
  }

  &.withImage {
    display: inline-flex;

    align-items: center;

    img {
      display: block;

      width: 32px;
      height: 24px;

      &.imageRadius {
        height: 32px;

        border-radius: 50%;
      }
    }
  }

  a {
    font-weight: 500;
    text-decoration: none;

    color: var(--color-white);

    &[data-focus-visible-added],
    &:hover {
      text-decoration: underline;
    }
  }
}