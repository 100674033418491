[dir='rtl'] {
  .legalPage {
    .body {
      .container {
        .content {
          ol,
          ul {
            li {
              margin: 6px 1em 6px 0;
            }
          }
        }
      }
    }
  }
}

[dir='ltr'] {
  .legalPage {
    .body {
      .container {
        .content {
          ol,
          ul {
            li {
              margin: 6px 0 6px 1em;
            }
          }
        }
      }
    }
  }
}

.legalPage {
  .header {
    background: url('./header-background.jpg') no-repeat;
    background-size: cover;

    .headerContent {
      padding: 90px 20px 60px 20px;

      text-align: center;

      background-image: linear-gradient(
        to bottom,
        rgba(17, 18, 23, 0.2),
        #111217 100%
      );

      .title {
        font-size: var(--font-size-huge);
        font-weight: 700;
      }

      .description {
        margin-top: 16px;

        font-size: var(--font-size-big);
        font-weight: 600;

        color: var(--color-gray);
      }
    }
  }

  .body {
    .container {
      .content {
        font-size: var(--font-size-big);
        font-weight: 700;
        color: var(--color-gray);

        h2 {
          font-size: var(--font-size-bigger);
          font-weight: 700;
        }

        h3 {
          font-size: var(--font-size-big);
          font-weight: 700;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: 1em;

          color: var(--color-white);

          &:not(:first-child) {
            margin-top: 1em;
          }
        }

        ol,
        ul {
          margin: 30px 0;
        }

        p {
          margin: 1em 0;
        }
      }
    }
  }
}
