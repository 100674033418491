[dir='rtl'] {
  .settingsUserDisplay {
    .avatar {
      .icon {
        left: 0;
      }
    }
  }
}

[dir='ltr'] {
  .settingsUserDisplay {
    .avatar {
      .icon {
        right: 0;
      }
    }
  }
}

.settingsUserDisplay {
  display: grid;

  grid-template-columns: auto 1fr;
  grid-gap: 20px;

  align-items: center;

  .avatar {
    position: relative;

    display: inline-block;

    &[data-focus-visible-added],
    &:hover {
      .icon {
        background-color: var(--color-dark-gray2);
      }
    }

    .icon {
      position: absolute;
      bottom: 0;

      width: 32px;
      height: 32px;

      padding: 6px;

      background-color: var(--color-dark-gray3);

      border-radius: 50%;

      svg {
        display: block;

        width: 100%;
        height: 100%;
      }
    }
  }

  .user {
    overflow: hidden;

    .username {
      overflow: hidden;

      font-weight: 700;

      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .muted {
      font-size: var(--font-size-small);
      font-weight: 500;
      color: var(--color-gray);

      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
}
