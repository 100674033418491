.ladderTournamentCard {
  background-color: #1c1e26;

  border-radius: 8px;

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.32);
  }

  .link {
    text-decoration: none;

    .header {
      position: relative;

      display: flex;
      justify-content: space-between;
      flex-direction: column;
      min-height: 144px;
      padding: 8px 12px;
      overflow: hidden;

      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      border-radius: 8px 8px 0 0;

      &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        content: '';

        background: rgb(0 0 0 / 30%);
      }

      .headerWrapper {
        position: relative;
        z-index: 2;

        display: flex;

        align-items: flex-start;
        justify-content: space-between;

        height: 100%;

        .status {
          margin-left: 10px;
          padding-top: 4px;
        }

        .game {
          display: grid;
          align-items: center;
          justify-content: flex-start;

          grid-auto-flow: column;
          grid-gap: 8px;
          width: 52%;

          .name {
            font-size: var(--font-size);
            font-weight: 700;

            color: var(--color-white);
          }
        }
      }

      .countdown {
        position: relative;
        z-index: 2;

        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 132px;
        max-height: 40px;

        padding: 4px 8px;

        background: rgba(15, 15, 18, 0.7);

        border-radius: 8px;

        .time {
          font-size: var(--font-size);
          font-weight: 700;
        }

        .remaining {
          display: flex;
          justify-self: start;

          font-size: var(--font-size-tiny);
          font-weight: 600;

          text-align: center;

          color: var(--color-gray);
        }
      }
    }

    .body {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 16px 16px 4px 16px;

      .detailHeader {
        .title {
          font-size: var(--font-size);
          font-weight: 700;

          color: var(--color-white);
        }
      }

      .reward {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 4px;

        .gaCoin {
          width: 24px;
          height: 24px;
        }

        :nth-child(2) {
          font-size: var(--font-size-small);
          font-weight: 700;

          color: var(--color-white);
        }
      }
    }

    .footer {
      padding: 0 16px 16px 16px;

      .muted {
        font-size: var(--font-size-tiny);
        font-weight: 700;

        color: var(--color-gray);
      }

      .info {
        font-size: var(--font-size-tiny);
        font-weight: 700;

        color: var(--color-white);
      }
    }
  }
}

@media (--min-md) {
  .ladderTournamentCard {
    .link {
      .header {
        .headerWrapper {
          .game {
            width: 45%;
          }
        }
      }
    }
  }
}
