/* stylelint-disable no-duplicate-selectors */
[dir='rtl'] {
  .markdown {
    ol {
      li {
        &:before {
          padding-left: 0.5em;
        }
      }
    }

    ul {
      li {
        &:before {
          right: 0;
        }
      }
    }
  }
}

[dir='ltr'] {
  .markdown {
    ol {
      li {
        &:before {
          padding-right: 0.5em;
        }
      }
    }

    ul {
      li {
        &:before {
          left: 0;
        }
      }
    }
  }
}

.markdown {
  font-size: var(--font-size);
  font-weight: 500;

  color: var(--color-white2);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5em;

    color: var(--color-white);

    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  h1 {
    font-size: var(--font-size-huge);
  }

  h2 {
    font-size: var(--font-size-bigger);
  }

  h3 {
    font-size: var(--font-size-big);
  }

  h4 {
    font-size: var(--font-size);
  }

  h5 {
    font-size: var(--font-size-small);
  }

  h6 {
    font-size: var(--font-size-smaller);
  }

  ol,
  ul {
    list-style: none;

    &:not(:first-child) {
      margin-top: 1em;
    }

    li {
      position: relative;

      text-indent: 20px;

      &:not(:first-child) {
        margin-top: 0.6em;
      }
    }
  }

  ol {
    counter-reset: ol-li;

    li {
      counter-increment: ol-li;

      &:before {
        display: inline-block;

        font-weight: bold;
        text-align: center;

        content: counter(ol-li) '.';
      }
    }
  }

  ul {
    li {
      &:before {
        position: absolute;
        top: 0.5em;

        width: 10px;
        height: 10px;

        margin-top: 3px;

        content: '';

        background-color: var(--color-red);

        border-radius: 50%;

        transform: translateY(-50%);
      }
    }
  }

  a {
    text-decoration: none;

    color: var(--color-red);

    &[data-focus-visible-added],
    &:hover {
      text-decoration: underline;
    }
  }
}
