.tippy {
  background-color: var(--color-dark-gray);
  color: var(--color-white);

  border-radius: 8px;

  :global {
    .tippy-content {
      padding: 10px 16px 10px 16px;

      font-size: var(--font-size-small);

      & > div {
        max-height: 260px;
        margin-right: -12px;
        overflow-y: auto;
      }
    }

    .tippy-arrow {
      color: var(--color-dark-gray);
    }
  }
}