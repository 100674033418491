[dir='rtl'] {
  .modal {
    .dialog {
      .header {
        .title {
          margin-left: 32px;
        }

        .closeButton {
          left: 6px;
        }
      }
    }
  }
}

[dir='ltr'] {
  .modal {
    .dialog {
      .header {
        .title {
          margin-right: 32px;
        }

        .closeButton {
          right: 6px;
        }
      }
    }
  }
}

.modal {
  position: fixed;
  z-index: 20;
  top: 140px;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  align-items: center;

  flex-direction: column;

  &:global {
    &.enter,
    &.exit.exit-active {
      :local(.dialog) {
        opacity: 0;

        transform: translateY(-40px);
      }
    }

    &.enter.enter-active,
    &.enter-done,
    &.exit {
      :local(.dialog) {
        opacity: 1;

        transform: translateY(0);
      }
    }
  }

  &.noTitle {
    .dialog {
      .header {
        background-color: var(--color-black);
      }

      .body {
        padding-top: 0;
      }
    }
  }

  .dialog {
    position: relative;
    z-index: 11;

    display: flex;

    flex-direction: column;

    width: 90%;
    max-height: 80%;

    overflow: hidden;

    background-color: var(--color-black);

    border: 2px solid var(--color-dark-gray2);

    border-radius: 16px;

    box-shadow: -136px 98px 67px rgba(0, 0, 0, 0.01),
      -77px 55px 57px rgba(0, 0, 0, 0.05), -34px 25px 42px rgba(0, 0, 0, 0.09),
      -9px 6px 23px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

    transition: all 300ms ease;

    transform: translateY(-40px);

    .header {
      position: relative;

      display: flex;

      align-items: center;
      justify-content: flex-end;

      flex: 0 0 44px;

      flex-direction: row;
      min-height: 50px;

      padding: 0 30px;

      background-color: var(--color-dark-gray);

      border-top-left-radius: 16px;
      border-top-right-radius: 16px;

      .title {
        flex: 1;

        font-size: var(--font-size-big);
        text-align: center;
      }

    }

    .closeButton {
      position: absolute;
      top: 50%;

      width: 32px;
      height: 32px;
      padding: 10px;

      cursor: pointer;

      border-radius: 6px;

      transform: translateY(-50%);

      &:focus,
      &:hover {
        background-color: var(--color-white3);

        svg {
          fill: var(--color-white);
        }
      }

      svg {
        display: block;

        max-width: 100%;
        height: 100%;

        fill: var(--color-gray);

        transition: fill 150ms ease;
      }
    }

    .body {
      flex: 1 1 auto;

      padding: 20px 30px;

      overflow: auto;

      .actions {
        display: flex;

        align-items: center;

        flex-direction: column-reverse;

        row-gap: 20px;

        column-gap: 10px;

        margin-top: 40px;

        > * {
          min-width: 100%;
        }
      }
    }
  }

  &.variantPrimary {
    .dialog {
      .closeButton {
        position: absolute;
      }
    }
  }

  &.variantSecondary {
    .dialog {
      background: var(--color-black4);

      .header {
        background: transparent;
      }

      .body {
        padding: 0 20px 20px;
      }
    }
  }

  &.variantThirty {
    .dialog {
      background: var(--color-black4);

      .body {
        padding: 44px 30px 42px;
      }
    }
  }
}

@media (--min-sm) {
  .modal {
    .dialog {
      width: 560px;

      .body {
        .actions {
          justify-content: flex-end;
          flex-direction: row;

          > * {
            min-width: 0;
          }
        }
      }
    }
  }
}

@media (--min-md) {
  .modal {
    &.sizeLarge {
      .dialog {
        width: 700px;
      }
    }

    &.variantSecondary {
      .dialog {
        .body {
          padding: 0 86px 50px;
        }
      }
    }

    &.variantThirty {
      .body {
        padding: 30px 30px 48px;
      }
    }
  }
}

@media (--min-lg) {
  .modal {
    &.sizeLarge {
      .dialog {
        width: 800px;
      }
    }
  }
}
