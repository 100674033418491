.metamask {
  .connectedModal {
    text-align: center;

    h4 {
      font-size: 24px;
      font-weight: 700;
    }

    p {
      margin-top: 9px;

      font-size: var(--font-size);
      font-weight: 600;

      color: var(--color-gray3);
    }

    .accountId {
      position: relative;

      display: flex;
      align-items:center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;
      max-width: 100%;
      margin: 71px auto 0;
      padding: 16px 12px;

      font-size: var(--font-size);
      font-weight: 700;

      background: var(--color-black2);

      border: 2px solid var(--color-dark-gray2);
      border-radius: 8px;

      box-shadow: -80px 375px 153px rgba(0, 0, 0, 0.01), -45px 211px 130px rgba(0, 0, 0, 0.05), -20px 94px 96px rgba(0, 0, 0, 0.09), -5px 23px 53px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

      .copiedFeedback {
        position: absolute;
        top: -55px;
        right: 0;
        padding: 12px 24px;

        background: var(--color-dark-gray2);

        border-radius: 50px;
      }
      
      .onlineIndicator {
        width: 12px;

        height: 12px;

        background-color: var(--color-green);

        border-radius: 50%;
      }

      .modalAccountId {
        width: 64%; 
        overflow: hidden;

        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .divider {
        height: 16px;

        border: 1px solid #4F515C;
        border-radius: 8px;
      }

      .copy {
        width: 24px;
        height: 24px;

        cursor:pointer
      }
    
    }

  }

  .noExtensionModal {
    text-align: center;

    svg {
      width: 88px;
      height: 88px;
    }

    h4{
      margin-top:48px;

      font-size: 24px;
      font-weight: 700;

      color: var(--color-white);
    }

    p {
      margin-top: 8px;

      font-size: var(--font-size);
      font-weight: 600;

      color: var(--color-gray3);
    }

    .downloadButton {
      width: 100%;
      height: 48px;
      margin-top: 48px;

      font-size: var(--font-size);
    }

    /* .downloadButton {
      width: 100%;
      height: 48px;
      margin-top: 48px;
      padding: 12px 106px;

      font-size: var(--font-size);
      font-weight: 700;
      text-decoration: none;

      background: #EB2C44;
      color: var(--color-white);

      border-radius: 8px;
    } */
  }

  .metamaskButton {
    
    height:48px;
    padding: 10px 20px;

    font-size: var(--font-size);
    font-weight: 700;

    background: var(--color-dark-gray2);

    border-radius: 8px;

    &.forMobile {
      height: 32px;
      padding: 6px;
    } 

    .buttonContent {
      display: flex;
      align-items:center;
      justify-content: space-between;

      font-size: var(--font-size-small);

      .iconDiv {
        position: relative;

        display: flex;

        .onlineIndicator {
          position: absolute;
          z-index: 2;
          top: 9px;
          left: 9px;

          width: 10px;

          height: 10px;

          background-color: var(--color-green);

          border-radius: 50%;

          transform: translate(50%, 50%);
        }

        svg {
          width: 24px;
          height: 24px;
        }

        .marginRight {
          margin-right: 10px;
        }
      }

      
    }
   
  }

  .button {
    margin-top: 119px;

    font-size: var(--font-size-small);
    font-weight: 600;
    text-decoration: underline;

    cursor: pointer;

    background: none;

    color: var(--color-red);
  }

  .underlined {
    text-decoration: underline;
  }
}

@media (--min-md) {
  .metamask {
    .connectedModal {
      .accountId {
        max-width: 75%;
        padding: 16px 24px;
      }
    }
    
  }
  
}

@media (--max-xs) {
  .metamask {
    .downloadApp {
      color: var(--color-red)
    }

    .button {
      margin-top: 50px;
    }

    .noExtensionModal {
      h4 {
        margin-top: 20px;
      }
    }
  }
}