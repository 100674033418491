.signUpButton {
  display: flex;
  align-items: center;

  font-size: var(--font-size-small);
  font-weight: 700;

  text-decoration: none;

  color: var(--color-red);

  svg {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
}

@media (--min-md) {
  .signUpButton {
    font-size: var(--font-size);

    svg {
      width: 24px;
      height: 24px;
      margin-left: 10px
    }
  }
}