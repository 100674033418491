[dir='rtl'] {
  .leaderboardTournamentPrizes {
    .body {
      .info {
        .detail {
          &.reward {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

[dir='ltr'] {
  .leaderboardTournamentPrizes {
    .body {
      .info {
        .detail {
          &.reward {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.leaderboardTournamentPrizes {
  overflow:hidden;

  background-color: var(--color-black3);

  border-radius: 8px;

  .header {
    padding: 12px 10px;

    font-weight: 700;

    background-color: var(--color-dark-gray);
    color: var(--color-white);

    border-radius: 8px 8px 0 0;

    transition: padding 150ms ease;
  }

  .body {
    display: grid;
    grid-gap: 16px;
    max-height: 294px;

    padding: 24px 8px;
    overflow-y: auto;

    border-radius: 0 0 8px 8px;

    transition: padding 150ms ease;

    .info {
      display: flex;

      align-items: center;
      justify-content: space-between;

      padding: 20px 10px;

      font-size: var(--font-size);
      font-weight: 600;

      background-color: var(--color-dark-gray);
      color: var(--color-white);

      border-radius: 8px;

      transition: padding 150ms ease;

      .detail {
        display: grid;
        align-items: center;

        grid-template-columns: repeat(2, auto);
        grid-gap: 4px;

        font-size: var(--font-size-small);
        font-weight: 500;

        color: var(--color-gray);

        &.reward {
          color: var(--color-white);

          p {
            font-size: var(--font-size-small);
            font-weight: 700;
          }
        }

        .gaCoin{
          width: 24px;
          height: 24px;
        }

        svg {
          display: block;

          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

@media (--min-xs) {
  .leaderboardTournamentPrizes {
    .header {
      padding: 12px 32px;
    }

    .body {
      padding: 24px;

      .info {
        padding: 16px;
      }
    }
  }
}

@media (--min-lg) {
  max-height: 806px;
  overflow-y: auto;
}