.landingMenu {
  .landingSideNav {
    position: fixed;
    z-index: 11;
    top: 0;
    right: 0;
    bottom: 0;

    width: 216px;

    min-height: 100%;
    padding: 17px 17px 17px 24px;
    overflow: hidden;

    background-color: var(--color-black2);

    transition: right 300ms ease, width 300ms ease;

    .closeContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  button {
    display:flex;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .links {
    display:flex;
    align-items: center;
    justify-content: center;

    .link {
      margin-right: 10px;

      font-size: var(--font-size-smaller);
      font-weight: 600;
      text-decoration: none;

      cursor: pointer;

      color: var(--color-white);
    }

    .aboutUs {
      display: flex;
      align-items: center;

      color: var(--color-gray4);

      svg {
        width: 24px;
        
        height: 24px;
        margin-left: 4px;
      }
    }

  }

  &.mobile {
    .mobileContent {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;

      .links {
        align-items: start;
        flex-direction: column;
        margin-top: 37px;

        .link {
          margin-bottom: 16px;

          font-size: var(--font-size-small);
          font-weight: 500;
        }
      }

      .supportCenter {
        .title {
          margin-bottom: 16px;

          font-size: var(--font-size-smaller);
          font-weight: 600;

          color: var(--color-gray4);
        }

        .tabs {
          display: flex;
          margin-bottom: 16px;

          .tab {
            font-size: var(--font-size-small);
            font-weight: 500;
            text-decoration: none;

            color: var(--color-gray);
          }

          svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }
        }

        .languageSelector {
          margin-bottom: 32px;
        }
      }
    }
   
  }
 
}

@media (--min-md) {
  .landingMenu {
    padding: 0 12px;

    

    .links{
      .seperator {
        height: 24px;
        margin-right: 12px;

        border-left: 1px solid #464853;
      }

      .link {    
        margin-right: 12px;

        font-size: var(--font-size-smaller);
      }

      .link:hover {
        color: var(--color-red);

        transition: 0.3s;
      }

      .aboutUs:hover {
        color: var(--color-white);

        transition: 0.3s;
      }

    }
  }
}
@media (--min-lg) {
  .landingMenu {
    position: absolute;
    left: 50%;

    transform: translateX(-50%);
  }
}
@media (--min-xl) {
  .landingMenu {
    padding: 0 12px 0 52px;

    .links{
      .seperator {
        margin-right: 32px;
      }

      .link {    
        margin-right: 40px;

        font-size: var(--font-size);
      }
    }
  }
}