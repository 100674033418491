.duelStatus {
  padding: 4px 24px;

  font-size: var(--font-size-smaller);
  font-weight: 600;
  text-align: center;

  background: var(--color-black4);

  border-radius: 24px;

  &.canceled,
  &.declined,
  &.in_dispute {
    color: var(--color-red);

    border-color: var(--color-red);
  }

  &.ended,
  &.expired {
    color: var(--color-gray);

    border-color: var(--color-gray);
  }

  &.in_progress {
    color: var(--color-orange);

    border-color: var(--color-orange);
  }

  &.open {
    color: var(--color-green);

    border-color: var(--color-green);
  }

  &.checking_in,
  &.declaring_result,
  &.invited {
    color: var(--color-yellow);

    border-color: var(--color-yellow);
  }
}
