.partners {
  margin: 0 auto;

  .content {
    margin-top: 20px;

    .slider {
      max-width: calc(100vw - 60px);

      .slide {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 238px;
        height: 140px;
        margin: 0 10px;

        background-color: var(--color-dark-gray2);

        border-radius: 16px;
      }
    }
  }

  .partner {
    .icon {
      display: block;
      max-width: 100%;

      margin: 0 auto;

      &.papara {
        margin-top: 16px;
      }

      &.amazon {
        max-width: 90%;
        margin-top: 10px;
      }
    }
  }
}

@media (--min-sm) {
  .partners {
    .slider {
      max-width: none;
    }
  }
}