[dir='rtl'] {
  .duelCancel {
    .wrapper {
      .message {
        margin-left: 10px;
      }
    }
  }
}

[dir='ltr'] {
  .duelCancel {
    .wrapper {
      .message {
        margin-right: 10px;
      }
    }
  }
}

.duelCancel {
  .wrapper {
    display: flex;

    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    padding: 24px 20px;

    background-color: var(--color-black3);

    border-radius: 8px;

    .message {
      margin-bottom: 14px;

      font-size: var(--font-size-small);
      font-weight: 500;
      text-align: center;

      color: var(--color-gray);
    }
  }
}

@media (--min-md) {
  .duelCancel {
    .wrapper {
      flex-direction: row;

      .message {
        margin-bottom: 0;

        text-align: left;
      }
    }}
}
