[dir='rtl'] {
  .duelPlayers {
    .header {
      .game {
        margin-left: 16px;
      }
    }
  }
}

[dir='ltr'] {
  .duelPlayers {
    .header {
      .game {
        margin-right: 16px;
      }
    }
  }
}

.duelPlayers {
  .header {
    display: flex;

    flex: 1;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    .game {
      display: grid;

      grid-template-columns: auto auto;

      grid-gap: 8px;

      align-items: center;
    }

    .details {
      display: grid;

      grid-template-columns: auto;

      grid-gap: 16px;

      align-items: center;

      .createdAt {
        font-size: var(--font-size-small);
        font-weight: 500;
        color: var(--color-gray);
        text-align: center;
      }
    }
  }

  .content {
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;

    align-items: center;

    .icon {
      display: none;

      svg {
        display: block;

        width: 29px;
        height: 29px;
      }
    }

    .user {
      display: flex;

      flex-direction: column;

      align-items: center;
      align-self: stretch;

      justify-content: center;

      padding: 8px 14px 12px 14px;

      overflow: hidden;

      font-size: var(--font-size);
      font-weight: 600;
      color: var(--color-white);
      text-align: center;
      text-decoration: none;

      background-color: var(--color-dark-gray);
      border-radius: 8px;

      transition: background-color 100ms ease;

      &[data-focus-visible-added],
      &:hover {
        background-color: var(--color-dark-gray2);
      }

      .fullName {
        font-weight: 700;
      }

      .userName {
        margin: 15px 0 4px 0;
      }

      .gameName {
        font-size: var(--font-size-smaller);
        color: var(--color-gray);
      }

      .line {
        width: 150%;
        min-height: 1px;

        margin: 12px 0;

        background: repeating-linear-gradient(
          to right,
          var(--color-gray2),
          var(--color-gray2) 10px,
          transparent 10px,
          transparent 15px
        );
      }

      .invited,
      .notReady {
        font-size: var(--font-size-small);
        color: var(--color-yellow);
      }

      .ready,
      .accepted {
        font-size: var(--font-size-small);
        color: var(--color-green);
      }

      .declined {
        font-size: var(--font-size-small);
        color: var(--color-red);
      }

      &.waiting {
        font-size: var(--font-size-small);
        font-weight: 600;
        line-height: 1.21;
        color: var(--color-gray);
        text-align: center;

        border: 1px dashed var(--color-gray2);

        svg {
          display: block;

          width: 40px;
          height: 40px;

          margin-bottom: 12px;

          opacity: 0.7;
        }
      }
    }
  }
}

@media (--min-xs) {
  .duelPlayers {
    .content {
      grid-template-columns: 1fr auto 1fr;

      .icon {
        display: block;
      }
    }
  }
}

@media (--min-sm) {
  .duelPlayers {
    .header {
      flex-direction: row;

      justify-content: space-between;

      .details {
        grid-template-columns: auto auto;
      }
    }
  }
}
