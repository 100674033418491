.footer {
  padding: 30px 0 90px 0;

  border-top: 1px solid rgba(255, 255, 255, 0.2);

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;

    .column {
      display: flex;

      align-items: center;

      flex-direction: column;

      gap: 24px;

      font-size: var(--font-size-tiny);

      text-align: center;

      color: var(--color-white2);

      .logo {
        display: block;

        width: auto;
        height: 16px;
      }

      .notice {
        font-size: var(--font-size-smaller);
      }

      .copyright {
        font-weight: 600;
      }

      .navigation {
        display: flex;

        justify-content: center;

        flex-direction: row;

        flex-wrap: wrap;

        gap: 20px;

        &.authenticated {
          gap: 24px;
        }

        .navColumn {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 12px;

          font-size: var(--font-size-smaller);

          .navHeader {
            font-size: var(--font-size-small);
            font-weight: 700;

            color: var(--color-white);
          }
        }

        a {
          min-width: calc(33% - 15px);

          font-weight: 500;

          text-decoration: none;

          color: var(--color-white2);

          &[data-focus-visible-added],
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .social {
        display: flex;

        align-items: center;

        flex-direction: row;

        gap: 12px;

        width: 40%;

        .socialLink {
          svg {
            display: block;

            width: auto;
            height: 24px;

            fill: var(--color-white2);

            transition: all 150ms ease;

            circle {
              fill: var(--color-white2);

              transition: all 150ms ease;
            }
          }

          &[data-focus-visible-added],
          &:hover {
            svg {
              fill: var(--color-white);

              circle {
                fill: var(--color-white);
              }
            }
          }
        }
      }

      &.menu {
        margin: 24px 0;
      }

      &.lastCol {
        /* stylelint-disable-next-line plugin/selector-tag-no-without-class */
        & > div {
          width: 100%;
        }
      }
    }
  }
}

@media (--max-xs) {
  .footer {
    .container {
      .column {
        .navigation {
          flex-direction: column;
          gap: 32px;
        }
      }
    }
  }
}

@media (--min-md) {
  .footer {
    .container {
      .column {
        .navigation {
          gap: 18px;

          &.authenticated {
            gap: 10px;
          }

        }

      }
    }
  }
}

@media (--min-xl) {
  .footer {
    .container {
      .column {
        .navigation {
          gap: 50px;

          &.authenticated {
            gap: 25px;
          }
        }
      }
    }
  }
}

@media (--min-xxl) {
  .footer {
    .container {
      .column {
        .navigation {
          gap: 80px;

          &.authenticated {
            gap: 50px;
          }
        }
      }
    }
  }
}

@media (--min-md) {
  [dir='rtl'] {
    .footer {
      .container {
        .column {
          text-align: right;
        }
      }
    }
  }

  [dir='ltr'] {
    .footer {
      .container {
        .column {
          text-align: left;
        }
      }
    }
  }

  .footer {
    padding: 30px 0;

    .container {
      align-items: flex-start;
      flex-direction: row;

      gap: 48px;

      .column {
        align-items: flex-start;

        width: 33%;

        text-align: right;

        .navigation {
          justify-content: flex-start;
        }

        .social {
          justify-content: flex-start;
        }

        &.menu {
          margin: 0;
        }

        &.lastCol {
          width: 134px;
        }
      }

      :nth-child(2) {
        width: auto;
      }
    }
  }
}
