.productCard {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  padding: 64px 23px;

  background: linear-gradient(
    136.97deg,
    rgba(255, 255, 255, 0) -18.14%,
    rgba(255, 255, 255, 0.04) 106.47%
  );
  backdrop-filter: blur(25px);

  border-radius: 16px;

  .icon {
    width: 64px;
    height: 64px;
  }

  .title {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;

    text-align: center;

    .main {
      font-size: var(--font-size-headline4);
      font-weight: 700;
    }

    .subTitle {
      font-size: var(--font-size-small);
      font-weight: 500;

      color: var(--color-gray);
    }
  }
}

@media (--min-md) {
  .productCard {
    .title {
      .main {
        font-size: var(--font-size-headline3);
      }

      .subTitle {
        font-size: var(--font-size-headline4);
      }
    }
  }
}

@media (--min-lg) {
  .productCard {
    width: 33%;
  }
}
