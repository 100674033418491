[dir='rtl'] {
  .userMenu {
    margin-left: -8px;

    .headerButton {
      :not(:first-child) {
        margin-right: 13px;
      }
    }

    .content {
      right: 0;
    }
  }
}

[dir='ltr'] {
  .userMenu {
    margin-right: -8px;

    .headerButton {
      :not(:first-child) {
        margin-left: 13px;
      }
    }

    .content {
      left: 0;
    }
  }
}

.userMenu {
  position: relative;

  display: flex;

  justify-content: center;

  flex-direction: row;

  height: 100%;

  .headerButton {
    display: flex;

    align-items: center;
    align-self: center;

    height: 60px;

    .username {
      display: none;

      font-size: var(--font-size);
      font-weight: 700;
    }

    .dropdownIcon {
      display: none;

      height: 8px;
    }
  }

  .content {
    position: absolute;
    z-index: 11;
    top: 100%;

    width: 320px;
    max-width: calc(100vw - 30px);

    margin-top: 20px;

    background-color: var(--color-black3);

    border-radius: 8px;

    box-shadow: 0 2px 41px 0 var(--color-black2);

    transition: all 150ms ease;

    &:global {
      &.enter,
      &.exit.exit-active {
        opacity: 0;

        transform: translateY(-10px);
      }

      &.enter.enter-active,
      &.exit {
        opacity: 1;

        transform: translateY(0);
      }
    }

    .header {
      padding: 10px 16px;

      font-size: var(--font-size-big);
      font-weight: 700;

      background-color: var(--color-dark-gray);

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .body {
      .link {
        display: flex;

        align-items: center;

        flex-direction: row;

        gap: 24px;

        padding: 15px 25px;

        font-weight: 600;

        text-decoration: none;

        color: var(--color-gray);

        &[data-focus-visible-added],
        &:hover {
          background-color: var(--color-dark-gray2);
          color: var(--color-white);

          svg {
            fill: var(--color-white);
          }
        }

        svg {
          display: block;

          width: 28px;
          height: 28px;

          fill: var(--color-gray);

          object-fit: contain;
        }
      }
    }

    .footer {
      background-color: var(--color-dark-gray);

      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;

      .signOut {
        display: flex;

        align-items: center;

        justify-content: center;

        flex-direction: row;

        gap: 10px;

        padding: 14px;

        font-weight: 600;

        text-decoration: none;

        color: var(--color-gray);

        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        &[data-focus-visible-added],
        &:hover {
          background-color: var(--color-dark-gray2);
          color: var(--color-white);

          svg {
            fill: var(--color-white);
          }
        }

        svg {
          display: block;

          height: 18px;

          fill: var(--color-gray);
        }
      }
    }
  }
}

@media (--min-md) {
  [dir='rtl'] {
    .userMenu {
      margin-left: 0;

      .content {
        right: unset;
        left: 0;
      }
    }
  }

  [dir='ltr'] {
    .userMenu {
      margin-right: 0;

      .content {
        right: 0;
        left: unset;
      }
    }
  }

  .userMenu {
    .headerButton {
      .username,
      .dropdownIcon {
        display: block;
      }
    }
  }
}
