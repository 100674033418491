[dir='rtl'] {
  .radioInput {
    &.hideRadioButton {
      .inlineLabel {
        padding-right: 0;
      }
    }

    .inlineLabel {
      padding-right: 24px;
    }
  }
}

[dir='ltr'] {
  .radioInput {
    &.hideRadioButton {
      .inlineLabel {
        padding-left: 0;
      }
    }

    .inlineLabel {
      padding-left: 24px;
    }
  }
}

.radioInput {
  display: inline-flex;

  align-items: center;

  width: 100%;
  height: 100%;

  padding: 24px 20px;

  cursor: pointer;

  background-color: var(--color-dark-gray);
  border: solid 1px transparent;
  border-radius: 8px;

  transition: border-color var(--transition-duration) ease;

  &.disabled {
    color: var(--color-dark-gray3);

    cursor: not-allowed;

    background-color: var(--color-dark-gray);

    .input {
      cursor: not-allowed;
    }

    .inlineLabel {
      cursor: not-allowed;
    }

    &:hover:not(.isActive) {
      border-color: transparent;
    }
  }

  &.contentCentered {
    .inlineLabel {
      justify-content: center;

      text-align: center;

      :first-child {
        :first-child {
          margin: 0 auto;
        }
      }
    }
  }

  &:hover {
    border-color: var(--color-green2);
  }

  &.hideRadioButton {
    input {
      display: none;
    }
  }

  .input {
    position: relative;

    width: 20px;
    height: 20px;

    background-color: var(--color-dark-gray);
    border: solid 1px var(--color-dark-gray4);
    border-radius: 50%;

    appearance: none;

    &:checked {
      border-color: var(--color-green2);

      &:after {
        position: absolute;

        top: 50%;
        right: 0;
        left: 0;

        width: 10px;
        height: 10px;

        margin: 0 auto;

        content: '';

        background-color: var(--color-green2);
        border-radius: 50%;

        transform: translateY(-50%);
      }
    }

    &[data-focus-visible-added] {
      border-color: var(--color-green2);
    }
  }

  .inlineLabel {
    flex: 1;

    font-size: var(--font-size-headline4);
    font-weight: 600;
    color: var(--color-gray);
  }

  &.isActive {
    border-color: var(--color-green2);

    .inlineLabel {
      color: var(--color-white);
    }
  }
}
