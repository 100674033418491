[dir='rtl'] {
  .layout {
    &.withStaticSidebar {
      .sidebar {
        right: 0;
      }

      .body {
        padding-right: 210px;

        &.games {
          padding-right: 0;
        }

        .header {
          right: 222px;
        }
      }
    }

    &:not(.withStaticSidebar) {
      .sidebar {
        left: 0;

        &:global {
          &.enter,
          &.exit.exit-active {
            opacity: 0;

            transform: translateX(-100%);
          }

          &.enter.enter-active,
          &.enter-done,
          &.exit {
            opacity: 1;

            transform: translateX(0);
          }
        }
      }
    }
  }
}

[dir='ltr'] {
  .layout {
    &.withStaticSidebar {
      .sidebar {
        left: 0;
      }

      .body {
        padding-left: 222px;

        &.games {
          padding-left: 0;
        }

        .header {
          left: 224px;

          &.games {
            left: 0;
          }
        }
      }
    }

    &:not(.withStaticSidebar) {
      .sidebar {
        right: 0;

        &:global {
          &.enter,
          &.exit.exit-active {
            opacity: 0;

            transform: translateX(100%);
          }

          &.enter.enter-active,
          &.enter-done,
          &.exit {
            opacity: 1;

            transform: translateX(0);
          }
        }
      }
    }
  }
}

.layout {
  display: flex;

  flex-direction: column;

  min-height: 100vh;

  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;

    width: 224px;
  }

  &.withStaticSidebar {
    .sidebar {
      z-index: 3;
    }
  }

  &:not(.withStaticSidebar) {
    .sidebar {
      z-index: 11;

      transition: opacity 200ms ease, transform 200ms ease;
    }
  }

  &.footerHidden {
    .body {
      .main {
        margin-bottom: 80px;
      }
    }
  }

  .body {
    position: relative;

    display: flex;

    flex: 1;

    flex-direction: column;

    .header {
      position: fixed;
      z-index: 8;

      top: 0;
      right: 0;

      left: 0;
    }

    .main {
      display: flex;

      flex: 1;

      flex-direction: column;

      margin-top: 64px;
      margin-bottom: 30px;
    }
  }
}

@media (--min-md) {
  .layout {
    .body {
      .main {
        margin-top: 80px;
      }
    }
  }
}

@media (--min-lg) {
  .layout {
    .main {
      margin-bottom: 0;
    }
  }
}
