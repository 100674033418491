.userGames {
  background-color: var(--color-black3);

  border-radius: 8px;

  .header {
    display: grid;
    align-items: center;

    grid-template-columns: 1fr 30%;
    grid-gap: 10px;

    padding: 10px 15px;

    background-color: var(--color-dark-gray);

    border-radius: 8px 8px 0 0;

    .title {
      font-weight: 700;

      color: var(--color-white);

      .count {
        font-size: var(--font-size);
        font-weight: 500;

        color: var(--color-gray2);
      }
    }
  }

  .body {
    display: grid;
    grid-gap: 10px;

    padding: 24px 16px 32px;
  }

  .warning {
    margin-top: 20px;

    font-size: var(--font-size-small);
    font-weight: 600;
    text-align: center;

    color: var(--color-gray);
  }
}
