[dir='rtl'] {
  .youtubeVideo {
    iframe,
    object,
    embed {
      right: 0;
    }
  }
}

[dir='ltr'] {
  .youtubeVideo {
    iframe,
    object,
    embed {
      left: 0;
    }
  }
}

.youtubeVideo {
  position: relative;

  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
  }
}
