.landingNumbers {
  padding: 34px 15px 66px 20px;

  background-color: var(--color-dark-gray);

  .title {
    margin-bottom: 30px;

    font-size: var(--font-size-headline3);
    font-weight: 700;
    text-align: center;
  }

  .numbers {
    display: flex;
    flex-direction: column;

    .number {
      .title {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 16px;
        padding: 32px 8px 0;

        text-align: center;

        .value {
          font-size: 56px;
          font-weight: 700;
        }

        .main {
          font-size: var(--font-size-headline4);
          font-weight: 700;

          span {
            color: var(--color-red);
          }
        }

        .subtitle {
          font-size: var(--font-size);
          font-weight: 500;

          color: var(--color-gray3);

        }

      }

      .divider {
        margin-top: 32px;

        border-bottom: 1px solid var(--color-dark-gray2);
      }
    }

    .number:last-child {
      .divider {
        display: none;
      }
    }

  
  }
}

@media (--min-lg) {
  .landingNumbers {
    padding: 64px 93px 64px 87px;

    .title {
      margin-bottom: 64px;

      font-size: 48px;
    }

    .numbers {
      justify-content: space-between;
      flex-direction: row;

      .number {
        display: flex;
        width: 33%;
        margin-left: 44px;

        .title {
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          padding: 0;

          .value {
            font-size: 72px;
          }

          .subtitle {
            font-size: var(--font-size-headline4);
            font-weight: 500;
          }
        }

        .divider {
          margin-top: 32px;
          margin-left: 48px;

          border-right: 1px solid var(--color-dark-gray2);
        }
      }
    
    }
  }
 
}
