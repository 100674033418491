[dir='rtl'] {
  .faq {
    .section {
      .question {
        .title {
          text-align: right;

          svg {
            margin-right: 30px;
          }
        }
      }
    }
  }
}

[dir='ltr'] {
  .faq {
    .section {
      .question {
        .title {
          text-align: left;

          svg {
            margin-left: 30px;
          }
        }
      }
    }
  }
}

.faq {
  .section {
    padding: 56px 20px;

    background-color: var(--color-black);

    .header {
      margin-bottom: 16px;

      text-align: center;

      .title {
        font-size: var(--font-size-headline3);
      }

      .subTitle {
        max-width: 75%;
        margin: 0 auto;

        font-weight: 600;

        color: var(--color-gray);

        a {
          text-decoration: none;

          color: var(--color-red);

          &[data-focus-visible-added],
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .question {
      &:not(:first-child) {
        margin-top: 16px;
      }

      .title {
        display: flex;

        align-items: center;
        justify-content: space-between;
        width: 100%;

        padding: 19px 16px;

        font-size: var(--font-size-small);
        font-weight: 700;

        cursor: pointer;

        background-color: var(--color-dark-gray);

        color: var(--color-white);

        border-radius: 16px;

        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.32);

        &[data-focus-visible-added],
        &:hover {
          background-color: var(--color-dark-gray);
        }

        &:active {
          background-color: var(--color-dark-gray2);
        }

        svg {
          width: 18px;
          height: 18px;

          fill: var(--color-red);

          transition: transform 150ms ease;
        }
      }

      .content {
        padding: 20px 24px;

        font-weight: 500;

        background-color: var(--color-black4);

        color: var(--color-gray);

        border-radius: 0 0 8px 8px;

        .youtubeVideo {
          &:not(:first-child) {
            margin-top: 20px;
          }
        }
      }

      &.active {
        .title {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          svg {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

@media (--min-md) {
  .faq {
    .section {
      width: 100%;
      padding: 64px 112px;

      .header {
        margin-bottom: 32px;

        .title {
          font-size: 48px;
        }
      }

      .question {
        .title {
          padding: 28px 28px 28px 24px;

          font-size: var(--font-size-headline3);
          font-weight: 900;

          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}
