.duelDisputeForm {
  display: flex;

  flex-direction: column;

  width: 100%;

  .children {
    margin-top: 10px;
  }

  .section {
    .sectionTop {
      display: flex;

      flex-direction: row;

      gap: 8px;

      align-items: center;

      .check {
        width: 24px;
        height: 24px;

        border: 2px solid var(--color-yellow);
        border-radius: 50%;

        &.completed {
          background-color: var(--color-white);
          border-color: var(--color-green);

          > svg {
            display: block;
          }
        }

        > svg {
          display: none;

          width: 100%;
          height: 100%;

          fill: var(--color-green);
        }
      }
    }

    .imageFile {
      position: relative;

      display: flex;

      flex-direction: column;

      align-items: center;

      .imageFileInput {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        width: 100%;

        pointer-events: none;

        opacity: 0;
      }

      .imageFileInputButton {
        display: flex;

        flex-direction: column;

        align-items: center;

        justify-content: center;

        width: 100%;

        padding: 16px 20px;

        font-size: var(--font-size-bigger);
        font-weight: 600;

        color: var(--color-white);
        text-align: center;

        cursor: pointer;

        border: 2px dashed var(--color-dark-gray3);
        border-radius: 8px;

        transition: background-color 100ms ease;

        svg {
          display: block;

          height: 64px;

          margin: 20px;

          fill: var(--color-white);
        }

        &[data-focus-visible-added],
        &:hover {
          background-color: var(--color-dark-gray2);
        }
      }

      .imageFilePreview {
        display: block;

        max-width: 100%;
        max-height: 200px;
      }

      .imageFileInputReUploadButton {
        display: block;

        margin-top: 12px;

        font-size: var(--font-size-small);
        font-weight: 600;

        color: var(--color-gray);

        text-decoration: underline;

        cursor: pointer;

        transition: color 150ms ease;

        &[data-focus-visible-added],
        &:hover {
          color: var(--color-white);
        }
      }
    }
  }

  .result {
    display: flex;

    flex-direction: column;

    align-items: center;

    &.error {
      .resultIcon {
        svg {
          fill: var(--color-red);
        }
      }
    }

    &.success {
      .resultIcon {
        svg {
          fill: var(--color-green);
        }
      }
    }

    .resultIcon {
      width: 94px;
      height: 94px;

      overflow: hidden;

      background-color: var(--color-white);

      border-radius: 50%;

      svg {
        display: block;

        width: 100%;
        height: 100%;
      }
    }

    .resultTitle {
      margin-top: 30px;
    }

    .resultDescription {
      margin-top: 10px;

      font-size: var(--font-size);
      font-weight: 600;

      color: var(--color-gray);
    }

    .resultActions {
      display: flex;

      flex-direction: column;

      gap: 20px;

      width: 100%;

      margin-top: 40px;
    }
  }
}
