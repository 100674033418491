.select {
  position: relative;

  :global {
    .react-select {
      &-container {
        width: 100%;
      }

      &__control {
        cursor: pointer;

        background-color: var(--color-black2);

        border: 1px solid var(--color-dark-gray2);
        border-radius: 8px;

        box-shadow: none;

        &[data-focus-visible-added],
        &:hover {
          border-color: var(--color-red);
        }

        &--is-disabled {
          background-color: var(--color-dark-gray2);
        }

        &--menu {
          &-is-open {
            .react-select__arrowDown {
              transform: rotate(180deg);
            }
          }
        }
      }

      &__indicatorContainer{
        box-sizing: border-box;
        display: block;
        padding: 8px 20px 8px 8px;

        color: var(--color-gray3);

        -webkit-transition: color 150ms;
        transition: color 150ms;

        &:hover {
          color: hsl(0, 0%, 60%);
        }
      }

      &__arrowDown {
        display: inline-block;
        width: 14px;

        line-height: 1;

        fill: currentColor;
        stroke: currentColor;
        stroke-width: 0;

        transition: transform .3s ease-in-out;
      }

      &__value-container {
        padding: 10px 12px 0 var(--form-component-horizontal-padding);

        .react-select__placeholder {
          margin: 0;
        }
      }

      &__single-value {
        margin: 0;

        font-weight: 500;
        text-align: left;

        color: var(--color-white);
      }

      &__menu-portal {
        z-index: 100;
      }

      &__menu {
        z-index: 100;
        overflow: hidden;

        background-color: var(--color-black);

        border: 1px solid var(--color-dark-gray2);
        border-radius: 8px;

        box-shadow: none;
      }

      &__menu-list {
        padding: 0;
      }

      &__option {
        padding: 12px 16px;

        font-size: var(--font-size-small);

        font-weight: 500;
        text-align: left;

        background-color: var(--color-black);

        color: var(--color-white);

        border-top: 1px solid var(--color-dark-gray2);

        &:first-child {
          border-top: none;
        }

        &:hover, &--is-selected {
          background-color: var(--color-dark-gray2);
        }

        &--is-selected {
          font-weight: 600;
        }

        &--is-disabled {
          cursor: not-allowed;

          background-color: #1c1e25;
          color: var(--color-gray4);

          &:hover {
            background-color: #1c1e25;
          }
        }
      }
    }
  }

  &.disabled{
    cursor: not-allowed;
  }

  &.focused {
    :global {
      .react-select {
        &__control {
          border-color: var(--color-red);
        }
      }
    }
  }

  &.sizeSmall {
    :global {
      .react-select {
        &__control {
          height: 40px;

          font-size: var(--font-size-small);
        }
      }
    }
  }

  &.sizeMedium {
    :global {
      .react-select {
        &__control {
          height: 44px;

          font-size: var(--font-size-small);
        }

        &__value-container{
          padding-top: 16px;
        }
      }
    }
  }

  &.sizeLarge {
    :global {
      .react-select {
        &__control {
          height: 48px;

          font-size: var(--font-size-small);
        }
      }
    }
  }

  &.sizeXLarge {
    :global {
      .react-select {
        &__control {
          height: 56px;

          font-size: var(--font-size);
        }
      }
    }
  }
}