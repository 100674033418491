.section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 48px;
  padding: 64px 20px;
  overflow: hidden;

  .columnOne {
    display: flex;
    justify-content: center;
    width: 100%;

    .textPart {
      .title {
        margin-bottom: 8px;

        font-size: var(--font-size-headline4);
        font-weight: 700;
      }
    
      .subtitle {
        margin-bottom: 24px;

        font-size: var(--font-size-small);
        font-weight: 500;
      }
    }
  }

  .columnTwo {
    .visualPart {
      img {
        max-width: 100%;
      }
    }
  }

}

@media (--min-md) {
  .section {
    justify-content: space-evenly;
    flex-direction: row;

    &.turnElementsOver {
      flex-direction: row-reverse;
    }

    .columnOne {
      max-width: 46%;
    }

    .columnTwo {
      max-width: 35%;
    }
  }
}

@media (--min-lg) {
  .section {
    flex-wrap: nowrap;

    .columnOne {
      max-width: 46%;

      .textPart {
        .title {
          width: 100%;
          margin-bottom: 24px;

          font-size: 36px;
          line-height: 56px;
        }
    
        .subtitle {
          margin-bottom: 40px;

          font-size: var(--font-size-headline4);
          line-height: 32px;

          color: var(--color-gray3);
        }
      }
    }

   

    .columnTwo {
      max-width: 50%;

      .visualPart {
        margin-right: 0;
        margin-left: 0;
        
      }
    }

    
  }
}
