[dir='rtl'] {
  .ladderTournamentTopRank {
    .user {
      .details {
        margin-right: 10px;
      }
    }

    .reward {
      margin-right: 20px;

      .gaCoin {
        margin-left: 6px;
      }
    }
  }
}

[dir='ltr'] {
  .ladderTournamentTopRank {
    .user {
      .details {
        margin-left: 10px;
      }
    }

    .reward {
      margin-left: 20px;

      .gaCoin {
        margin-right: 6px;
      }
    }
  }
}

.ladderTournamentTopRank {
  display: flex;

  justify-content: space-between;

  flex: 1;

  padding: 16px 24px;

  text-decoration: none;

  background-color: var(--color-black3);

  border-radius: 8px;

  transition: background-color 150ms ease, padding 150ms ease;

  &[data-focus-visible-added],
  &:hover {
    background-color: var(--color-dark-gray2);
  }

  .user {
    display: flex;

    .details {
      display: flex;

      justify-content: space-around;

      flex-direction: column;

      font-size: var(--font-size);
      font-weight: 700;

      color: var(--color-white);

      .username {
        max-width: 150px;
        overflow: hidden;

        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .count {
      margin-top: 6px;

      .muted {
        font-weight: 500;

        color: var(--color-gray);
      }
    }
  }

  .reward {
    display: flex;

    align-items: center;

    font-size: var(--font-size);
    font-weight: 600;

    color: var(--color-white);

    .gaCoin {
      display: block;

      width: 24px;
      height: 24px;
    }
  }
}
