[dir='rtl'] {
  .checkbox {
    .inlineLabel {
      padding-right: 8px;
    }
  }
}

[dir='ltr'] {
  .checkbox {
    .inlineLabel {
      padding-left: 8px;
    }
  }
}

.checkbox {
  display: inline-flex;

  align-items: center;

  padding: 8px;

  cursor: pointer;

  border-radius: 6px;

  transition: background-color var(--transition-duration) ease;

  &:hover {
    background-color: var(--color-dark-gray2);
  }

  .input {
    width: 20px;
    height: 20px;

    border: 1px solid var(--color-gray3);
    border-radius: 4px;
    appearance: none;

    &:checked {
      background-color: var(--color-red);
      background-image: url('./check.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 10px auto;

      border-color: var(--color-red);
    }

    &[data-focus-visible-added] {
      outline: 1px solid var(--color-red);
    }
  }

  .inlineLabel {
    flex: 1;
  }

  &.isActive {
    .inlineLabel {
      color: var(--color-white);

      img[src*='data:image/svg+xml'] {
        filter: grayscale(1) brightness(2);
      }
    }
  }

  &.multiselect {
    color: var(--color-white);
  }

  &.sizeSmall {
    .input {
      width: 16px;
      height: 16px;
    }
  }
}
