[dir='rtl'] {
  .userHeader {
    .background {
      right: 0;
    }

    .actions {
      .goBack,
      .edit {
        text-align: left;

        &.edit {
          text-align: left;
        }

        &.goBack {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    .wrapper {
      .user {
        .username {
          .share {
            margin-right: 12px;
          }
        }
      }
    }
  }
}

[dir='ltr'] {
  .userHeader {
    .background {
      left: 0;
    }

    .actions {
      .goBack,
      .edit {
        text-align: right;

        &.edit {
          text-align: right;
        }

        &.goBack {
          svg {
            transform: rotate(0deg);
          }
        }
      }
    }

    .wrapper {
      .user {
        .username {
          .share {
            margin-left: 12px;
          }
        }
      }
    }
  }
}

.userHeader {
  position: relative;

  .background {
    position: absolute;
    top: 0;

    width: 100%;
    height: 210px;

    background: url('./bg.png') no-repeat bottom center;
    background-size: cover;
  }

  .actions {
    position: relative;

    display: grid;

    align-items: center;
    justify-content: space-between;

    grid-template-columns: repeat(2, auto);

    grid-gap: 30px;

    padding: 12px 16px;

    .goBack,
    .edit {
      font-size: var(--font-size);
      font-weight: 700;
      text-decoration: none;

      cursor: pointer;

      color: var(--color-gray);

      &.edit {
        display: grid;

        align-items: center;
        justify-content: flex-end;

        grid-template-columns: repeat(2, auto);
        grid-gap: 5px;
      }

      &[data-focus-visible-added],
      &:hover {
        text-decoration: underline;

        svg {
          filter: drop-shadow(0px 2px 4px var(--color-gray));
        }
      }

      svg {
        display: block;

        height: 16px;
      }
    }
  }

  .wrapper {
    position: relative;

    display: grid;

    grid-gap: 26px;

    padding: 12px 16px;

    .user {
      display: flex;

      align-items: center;
      justify-content: center;

      flex-direction: column;

      text-align: center;

      .avatar {
        margin-bottom: 10px;
      }

      .username {
        display: grid;

        align-items: center;
        justify-content: center;

        grid-template-columns: repeat(2, auto);
        grid-gap: 8px;

        margin-bottom: 4px;

        font-weight: 700;

        color: var(--color-white);

        .share {
          cursor: pointer;

          svg {
            display: inline-block;

            height: 18px;
          }

          &[data-focus-visible-added],
          &:hover {
            text-decoration: underline;

            svg {
              filter: drop-shadow(0px 2px 4px var(--color-gray));
            }
          }
        }
      }

      .date {
        font-size: var(--font-size-small);
        font-weight: 500;

        color: var(--color-gray);
      }
    }

    .details {
      display: grid;

      grid-template-rows: auto auto;
      grid-template-columns: 1fr;
      grid-gap: 32px;

      .stats {
        display: grid;

        align-items: center;
        align-self: flex-end;
        justify-content: space-evenly;

        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        .stat {
          font-size: var(--font-size-headline4);
          font-weight: 600;
          text-align: center;

          color: var(--color-white);

          :last-child {
            font-size: var(--font-size-small);
            font-weight: 500;

            color: var(--color-gray);
          }

          .muted {
            font-size: var(--font-size-headline4);
            font-weight: 600;
            text-align: center;

            color: var(--color-gray);
          }
        }
      }

      .button {
        display: grid;

        align-self: flex-start;
        justify-content: center;

        grid-template-columns: repeat(1, minmax(min-content, 250px));
      }
    }
  }
}

@media (--min-lg) {
  .userHeader {
    .bg {
      height: 80%;
    }

    .actions {
      padding: 22px 24px;
    }

    .wrapper {
      grid-template-columns: 3fr 4fr;

      padding: 22px 24px;
    }
  }
}

@media only screen and (min-width: 1810px) {
  .userHeader {
    .headerContainer {
      width: 1810px;
    }
  }
}

@media only screen and (min-width: 2250px) {
  .userHeader {
    .headerContainer {
      width: 2250px;
    }
  }
}
