.chatNotice {
  margin-bottom: 20px;

  font-size: var(--font-size-small);
  font-weight: 500;

  text-align: center;

  color: var(--color-gray);

  .proofNotice {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    gap: 4px;

    max-width: 50%;
    margin: 0 auto;

    padding: 0 0 8px 0;

    font-size: var(--font-size);
    font-weight: 600;

    background-color: var(--color-dark-gray2);

    .imageLink {
      display: block;

      &[data-focus-visible-added],
      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      img {
        display: block;

        max-width: 100%;
        height: auto;

        margin: 0 auto;

        transition: transform 50ms ease-in-out;
      }
    }
  }

  .content {
    display: inline-block;

    min-width: 80%;

    padding: 8px 20px;

    font-size: var(--font-size);
    font-weight: 600;

    background-color: var(--color-dark-gray2);

    border-radius: 18px;

    a {
      text-decoration: underline;

      color: inherit;

      transition: color 100ms ease;

      &[data-focus-visible-added],
      &:hover {
        color: var(--color-white);
      }
    }
  }

  .dateTime {
    margin-top: 6px;

    font-size: var(--font-size-smaller);
    font-weight: 500;
  }
}

@media (--min-md) {
  .chatNotice {
    .proofNotice {
      max-width: 320px;
    }
  }
}
