.duelInDispute {
  padding: 20px 24px;

  background-color: var(--color-black3);
  border-radius: 8px;

  .waiting {
    display: grid;

    grid-template-columns: 1fr auto;

    grid-gap: 12px;

    align-items: center;
    justify-content: center;

    .info {
      font-size: var(--font-size-small);
      font-weight: 500;
      color: var(--color-gray);
    }

    .joinButton {
      text-align: center;
    }
  }

  .verified {
    .title {
      margin: 12px 0 10px 0;

      font-size: var(--font-size-small);
      font-weight: 700;
    }

    .description {
      font-size: var(--font-size-small);
      font-weight: 600;
      color: var(--color-gray);
    }
  }

  .declined,
  .processing {
    .title {
      display: grid;

      grid-template-columns: auto 1fr;

      grid-gap: 4px;

      align-items: center;

      margin: 12px 0 10px 0;

      font-size: var(--font-size-small);
      font-weight: 700;

      svg {
        display: inline-block;

        width: auto;
        height: 20px;
      }
    }

    .description {
      font-size: var(--font-size-small);
      font-weight: 600;
      color: var(--color-gray);

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .actions {
      display: flex;

      flex-direction: row;

      flex-wrap: wrap;

      gap: 12px;

      justify-content: space-around;

      margin-top: 24px;
    }
  }
}
