.shareButton {
  align-self: center;

  cursor: pointer;

  svg {
    display: block;

    width: 14px;
    height: auto;
  }

  &[data-focus-visible-added],
  &:hover {
    svg {
      filter: drop-shadow(0px 2px 4px var(--color-gray));
    }
  }
}

.content {
  display: grid;

  grid-template-columns: repeat(4, auto);
  grid-gap: 24px;

  justify-content: space-between;

  .copyLink {
    cursor: pointer;

    svg {
      display: block;

      width: auto;
      height: 24px;

      &[data-focus-visible-added],
      &:hover {
        circle {
          fill: var(--color-white);
        }
      }
    }
  }

  :not(.copyLink) {
    svg {
      display: block;

      width: auto;
      height: 24px;

      fill: var(--color-gray);

      &[data-focus-visible-added],
      &:hover {
        fill: var(--color-white);
      }
    }
  }
}
