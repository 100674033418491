[dir='rtl'] {
  .duelCard {
    .wrapper {
      .content {
        .opponents {
          .player2 {
            text-align: left;
          }
        }

        .gameDetails {
          .coins,
          .gameMode {
            .gaCoin,
            svg {
              margin-left: 7px;
            }
          }
        }
      }
    }
  }
}

[dir='ltr'] {
  .duelCard {
    .wrapper {
      .content {
        .opponents {
          .player2 {
            text-align: right;
          }
        }

        .gameDetails {
          .coins,
          .gameMode {
            img {
              margin-right: 7px;
            }

            svg {
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}

.duelCard {
  display: grid;

  .wrapper {
    display: grid;

    grid-template-rows: auto 1fr;
    grid-auto-flow: row;

    overflow: hidden;

    font-size: var(--font-size-small);
    text-decoration: none;

    color: var(--color-white);

    border-radius: 8px;

    transition: background-color var(--transition-duration) ease;

    &[data-focus-visible-added],
    &:hover {
      background-color: var(--color-dark-gray2);
    }

    .header {
      position: relative;
      height: 144px;

      background-color: var(--color-dark-gray);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      border-radius: 8px 8px 0 0;

      &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        content: '';

        background: linear-gradient(107.16deg, rgba(0, 0, 0, 0.63) 0%, rgba(0, 0, 0, 0) 42.43%);
      }

      .headerWrapper {
        position: relative;
        z-index: 2;

        display: flex;

        align-items: flex-start;
        justify-content: space-between;

        height: 100%;

        padding: 8px 12px;

        .status {
          margin-left: 10px;
          padding-top: 4px;
        }

        .game {
          display: grid;
          align-items: center;
          justify-content: flex-start;

          grid-auto-flow: column;
          grid-gap: 8px;
          width: 45%;

          .duelGame {
            font-size: var(--font-size);
            font-weight: 700;

            color: var(--color-white);
          }
        }
      }
    }

    .content {
      display: grid;

      grid-template-rows: 1fr auto;
      grid-row-gap: 12px;
      padding: 16px;

      background: #1c1e26;

      .opponents {
        display: grid;

        align-items: flex-start;
        justify-items: flex-start;
        grid-template-columns: 1fr 48px 1fr;
        grid-gap: 20px;

        .versus {
          margin-top: 8px;
          padding: 4px 16px;

          font-size: var(--font-size-smaller);
          font-weight: 600;

          background-color: var(--color-black4);
          color: var(--color-gray3);

          border: 1px solid var(--color-dark-gray3);
          border-radius: 8px;
        }

        .player {
          font-size: var(--font-size-small);
          font-weight: 700;

          color: var(--color-white);

          div {
            display: flex;

            .cup {
              width: 16px;
              height: 16px;
            }

            p {
              margin-left: 4px;

              font-size: var(--font-size-tiny);
              font-weight: 700;
              line-height: 16px;

              color: #d2a889;
            }
          }

          .lost {
            border-bottom: 1px solid var(--color-red);
          }

          .won {
            border-bottom: 1px solid var(--color-green);
          }

          &.waiting {
            min-width: 0;

            max-width: 108px;
            padding: 3px 8px;

            font-size: var(--font-size-small);
            font-weight: 500;
            line-height: 20px;
            text-align: right;

            color: #48b474;
          }
        }

        .vs {
          justify-self: center;

          padding: 1px 16px;
        }

        .player2 {
          justify-self: flex-end;

          font-size: var(--font-size-small);
          font-weight: 700;
        }
      }

      .gameDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: var(--font-size-small);
        font-weight: 600;

        color: var(--color-gray);

        .coins {
          display: flex;
          align-items: center;

          font-size: var(--font-size-small);
          font-weight: 700;

          color: var(--color-white);

          .gaCoin {
            width: 24px;
            height: 24px;
            margin-right: 4px !important;
          }
        }

        .gameMode {
          display: flex;

          align-items: center;
          max-width: 52%;

          svg {
            display: block;

            height: 20px;
          }
        }
      }
    }

    &.expand {
      .header {
        height: 120px;

        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;

        .headerWrapper {
          align-items: flex-start;

          padding: 15px 8px;
        }
      }
    }
  }
}

@media (--min-md) {
  .duelCard {
    .wrapper {
      .header {
        .headerWrapper {
          .game {
            width: 45%;
          }
        }
      }
    }
  }
}