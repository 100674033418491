.settingsPasswordChange {
  .message {
    margin-top: 24px;

    font-size: var(--font-size-small);
    font-weight: 500;
    color: var(--color-gray);
  }

  .form {
    margin: 20px 0;

    .fieldset {
      display: grid;

      grid-template-columns: 1fr;
      grid-row-gap: 16px;
      grid-column-gap: 24px;

      > * {
        margin: 0;
      }

      .buttonWrapper {
        display: flex;

        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media (--min-lg) {
  .settingsPasswordChange {
    .form {
      .fieldset {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 20px;

        :nth-child(1) {
          grid-area: 1 / 1 / 2 / 3;
        }

        :nth-child(2) {
          grid-area: 2 / 1 / 3 / 3;
        }

        :nth-child(3) {
          grid-area: 2 / 3 / 3 / 5;
        }

        :nth-child(4) {
          grid-area: 3 / 3 / 5 / 5;
        }

        .buttonWrapper {
          justify-content: flex-end;
        }
      }
    }
  }
}
