.tournamentChat {
  display: flex;

  flex-direction: column;
  width: 100%;

  &.expanded {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;

    height: 100vh;

    .body {
      .content {
        max-height: 72vh;
      }
    }
  }

  .header {
    display: flex;

    align-items: center;

    flex: 0 0 auto;

    flex-direction: row;

    height: 48px;

    padding: 0 12px;

    background-color: var(--color-dark-gray);

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .headerSide {
      flex: 1;

      .headerButton {
        display: block;

        width: 32px;
        height: 32px;

        padding: 4px;

        cursor: pointer;

        border-radius: 4px;

        transition: background-color 100ms ease;

        &[data-focus-visible-added],
        &:hover {
          background-color: var(--color-dark-gray3);

          svg {
            fill: var(--color-white);
          }
        }

        svg {
          display: block;

          width: 100%;
          height: 100%;

          fill: var(--color-gray);

          transition: fill 100ms ease;
        }
      }

      &.headerRight {
        display: flex;

        justify-content: flex-end;

        flex-direction: row;
      }
    }

    .title {
      padding: 0 10px;

      font-size: var(--font-size-big);
      font-weight: 700;
      text-align: center;
    }
  }

  .body {
    display: flex;

    flex: 1 1 auto;
    flex-direction: column;

    min-height: 0;

    background-color: var(--color-black3);

    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    > * {
      padding-right: 12px;
      padding-left: 12px;

      &:not(:last-child) {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    .content {
      display: flex;

      justify-content: flex-start;

      flex: 1 0 auto;

      flex-direction: column;

      max-height: 240px;

      padding-top: 24px;
      padding-bottom: 24px;

      .messageBox {
        overflow-y: auto;
      }

      > * {
        &:not(:first-child) {
          margin-top: 30px;
        }
      }
    }

    .input {
      padding-top: 12px;
      padding-bottom: 12px;

      border-top: 1px solid var(--color-dark-gray2);

      .form {
        display: grid;

        grid-template-columns: 1fr auto;
        grid-gap: 8px;
      }
    }
  }

  .chatInput {
    border-radius: 24px;
  }
}

@media (--min-xs) {
  .tournamentChat {
    &.expanded {
      .body {
        .content {
          padding: 24px 16px;
        }
      }
    }
  }
}

@media (--min-sm) {
  .tournamentChat {
    &.expanded {
      .body {
        .content {
          max-height: 78vh;
          padding: 24px 32px;
        }
      }
    }
  }
}

@media (--min-md) {
  .tournamentChat {
    &.expanded {
      .body {
        .content {
          max-height: 81.5vh;
        }
      }
    }
  }
}

@media (--min-lg) {
  .tournamentChat {
    &.expanded {
      .body {
        .content {
          max-height: 74vh;
        }
      }
    }
  }
}

@media (--min-xl) {
  .tournamentChat {
    &.expanded {
      .body {
        .content {
          max-height: 76vh;
        }
      }
    }
  }
}

@media (--min-xxl) {
  .tournamentChat {
    &.expanded {
      .body {
        .content {
          max-height: 80vh;
        }
      }
    }
  }
}
