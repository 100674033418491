[dir='rtl'] {
  .leaderboardTournamentTopRank {
    .user {
      .details {
        margin-right: 10px;
      }

      .count {
        svg {
          margin-left: 6px;
        }
      }
    }

    .reward {
      margin-right: 20px;

      .gaCoin {
        margin-left: 6px;
      }
    }
  }
}

[dir='ltr'] {
  .leaderboardTournamentTopRank {
    .user {
      .details {
        margin-left: 10px;
      }

      .count {
        svg {
          margin-right: 6px;
        }
      }
    }

    .reward {
      margin-left: 20px;

      .gaCoin {
        margin-right: 6px;
      }
    }
  }
}

.leaderboardTournamentTopRank {
  display: flex;

  justify-content: space-between;

  flex: 1;

  padding: 16px 10px 24px 10px;

  text-decoration: none;

  background-color: var(--color-black3);

  border-radius: 8px;

  transition: padding 150ms ease;

  &[data-focus-visible-added],
  &:hover {
    background-color: var(--color-dark-gray2);
  }

  .user {
    display: flex;

    .details {
      display: flex;

      justify-content: space-around;

      flex-direction: column;

      font-size: var(--font-size);
      font-weight: 700;

      color: var(--color-white);

      .username {
        max-width: 150px;
        overflow: hidden;

        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .count {
      display: grid;

      align-items: center;
      justify-content: flex-start;

      grid-template-columns: repeat(2, auto);
      grid-gap: 10px;

      .info {
        display: flex;

        align-items: center;

        flex-wrap: nowrap;
      }

      svg {
        display: inline-block;

        width: 16px;
        height: 16px;
      }
    }
  }

  .reward {
    display: flex;

    align-items: center;

    font-size: var(--font-size);
    font-weight: 600;

    color: var(--color-white);

    .gaCoin {
      display: block;

      width: 24px;
      height: 24px;
    }
  }
}

@media (--min-xs) {
  .leaderboardTournamentTopRank {
    padding: 16px 32px 24px 32px;
  }
}
