.toastsHandler {
  position: fixed;
  z-index: 100;
  top: 56px;
  left: 50%;

  width: 100%;

  pointer-events: none;

  transform: translateX(-50%);

  .toast {
    margin: 10px 0;

    pointer-events: all;
  }
}
