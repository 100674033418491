[dir='rtl'] {
  .ladderTournamentRank {
    .reward {
      .gaCoin {
        margin-left: 4px;
      }
    }
  }
}

[dir='ltr'] {
  .ladderTournamentRank {
    .reward {
      .gaCoin {
        margin-right: 4px;
      }
    }
  }
}

.ladderTournamentRank {
  &.me {
    .user {
      a {
        color: var(--color-red);
      }
    }

    .rankCount {
      color: var(--color-red);
    }
  }

  .trophies {
    svg {
      display: block;

      width: auto;
      height: 24px;

      margin: 0 auto;
    }
  }

  .user {
    a {
      text-decoration: none;

      color: var(--color-gray);

      transition: color 100ms ease;

      &[data-focus-visible-added],
      &:hover {
        text-decoration: underline;

        color: var(--color-white);
      }
    }
  }

  .count {
    font-size: var(--font-size-small);
    font-weight: 600;

    color: var(--color-white);
  }

  .rankCount {
    font-size: var(--font-size-small);
    font-weight: 600;

    color: var(--color-gray);
  }

  .reward {
    font-size: var(--font-size);
    font-weight: 600;

    vertical-align: middle;

    color: var(--color-white);

    .rewardDiv {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .empty {
      text-align: center;
    }

    .gaCoin {
      display: inline-block;

      width: auto;
      height: 24px;
    }
  }
}
