[dir='rtl'] {
  .filterSort {
    .wrapper {
      .buttonContainer {
        .headerButton {
          .icon {
            .filledIndicator {
              left: 0;
            }
          }
        }
      }

      .filterContainer {
        .close {
          text-align: left;
        }
      }
    }

    .verticalFilterSort {
      left: 0;

      &.closed {
        left: calc(var(--sidebar-right-width) * -1);
      }

      .toggle {
        left: 0;

        border-radius: 0px 4px 4px 0;

        svg {
          transform: rotate(180deg);

          &.opened {
            transform: rotate(0);
          }
        }

        &.open {
          left: var(--sidebar-right-width);
        }
      }
    }
  }
}

[dir='ltr'] {
  .filterSort {
    .wrapper {
      .buttonContainer {
        .headerButton {
          .icon {
            .filledIndicator {
              right: 25%;
            }
          }
        }
      }

      .filterContainer {
        .close {
          text-align: right;
        }
      }
    }

    .verticalFilterSort {
      right: 0;

      &.closed {
        right: calc(var(--sidebar-right-width) * -1);
      }

      .toggle {
        right: 0;

        svg {
          transform: rotate(0);

          &.opened {
            transform: rotate(180deg);
          }
        }

        &.open {
          right: var(--sidebar-right-width);
        }
      }
    }
  }
}

.filterSort {
  .body {
    display: flex;

    align-items: center;

    flex-direction: column;

    .filterForm {
      display: flex;

      flex-direction: column;
      width: 100%;

      height: 100%;

      .actions {
        display: flex;

        flex-direction: column;
      }
    }

    .filter,
    .sort {
      display: grid;

      grid-gap: 22px;

      margin-bottom: 22px;

      .content {
        display: grid;

        grid-gap: 16px;

        .dropdown {
          position: relative;

          .dropdownLabel {
            display: flex;

            align-items: center;
            justify-content: space-between;

            padding: 16px;

            font-size: var(--font-size);
            font-weight: 600;

            cursor: pointer;

            background-color: var(--color-black2);

            color: var(--color-gray);

            border: solid 2px var(--color-dark-gray2);
            border-radius: 8px;

            &::marker {
              display: none;
            }

            .label {
              display: flex;

              flex-direction: row;

              color: var(--color-white);

              [aria-disabled] {
                color: var(--color-gray);
              }

              svg {
                display: block;

                width: 20px;

                height: 20px;

                margin: 0 12px;
              }
            }

            svg {
              display: block;

              height: 6px;
            }
          }

          .dropdownItems {
            position: absolute;

            z-index: 2;

            width: 100%;
            max-height: 300px;

            margin-top: 8px;
            padding: 16px;
            overflow: auto;

            background-color: var(--color-black3);

            border-radius: 9px;
          }
        }
      }
    }

    .clearAll {
      padding: 10px;

      font-size: var(--font-size-small);
      font-weight: 600;

      cursor: pointer;

      color: var(--color-gray);

      &[data-focus-visible-added],
      &:hover {
        text-decoration: underline;
      }
    }

    .filterContainer {
      position: fixed;
      z-index: 11;

      bottom: 0;

      min-width: 100%;
      height: 70%;

      min-height: 320px;

      padding: 24px 30px;

      /* overflow-y: auto; */

      background-color: var(--color-black2);

      border-radius: 20px 20px 0 0;

      transition: transform 300ms ease, opacity 300ms ease;

      &:global {
        &.enter,
        &.exit.exit-active {
          opacity: 0;

          transform: translate(0, 100%);
        }

        &.enter.enter-active,
        &.exit {
          opacity: 1;

          transform: none;
        }
      }

      .filterForm {
        display: flex;

        flex-direction: column;

        height: 100%;

        .actions {
          display: flex;
          flex-direction: column;
          height: 110px;
        }
      }
    }
  }

  .wrapper {
    display: flex;

    flex-direction: column;

    .buttonContainer {
      display: grid;

      /* grid-template-columns: 1fr 1fr;
      grid-gap: 1px; */

      .separator {
        width: 1px;
        margin: 4px 0;

        border: solid 1px var(--color-gray2);
      }

      .headerButton {
        position: relative;

        display: grid;

        align-items: center;
        justify-content: center;

        flex: 1;

        grid-template-columns: repeat(2, auto);
        grid-gap: 14px;

        padding: 12px 0;

        font-size: var(--font-size);
        font-weight: 600;

        cursor: pointer;

        background-color: var(--color-dark-gray);
        color: var(--color-white);

        &.withDetail {
          grid-template-columns: repeat(3, auto);

          .detail {
            width: 150px;
            overflow: hidden;

            font-size: var(--font-size-small);
            text-overflow: ellipsis;
            white-space: nowrap;

            color: var(--color-gray);
          }
        }

        &[data-focus-visible-added],
        &:hover {
          background-color: var(--color-black2);
        }

        .icon {
          position: relative;

          .filledIndicator {
            position: absolute;
            top: 10%;
            right: 0;

            width: 9px;

            height: 9px;

            background-color: var(--color-red);

            border-radius: 50%;

            transform: translate(50%, -50%);
          }

          svg {
            display: block;

            width: 20px;
            height: 20px;

            fill: var(--color-gray);
          }
        }
      }
    }
  }

  .filterContainer {
    position: relative;

    .close {
      .closeButton {
        cursor: pointer;

        color: var(--color-white);

        svg {
          display: block;

          width: 18px;
          height: 18px;

          &[data-focus-visible-added],
          &:hover {
            fill: var(--color-red);
          }
        }
      }
    }

    .filterForm {
      .actions {
        .clearAll {
          margin-top: 8px;
          margin-bottom: 16px;
          padding: 10px;

          font-size: var(--font-size-small);
          font-weight: 600;

          cursor: pointer;

          color: var(--color-gray);

          &[data-focus-visible-added],
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .dropdown {
    position: relative;

    z-index: 12;

    .filterContainer {
      padding: 24px;

      overflow-y: auto;

      background-color: var(--color-black2);

      transition: height 150ms ease;

      .actions {
        display: flex;

        align-items: center;

        flex-direction: column;

        .rollUp {
          margin: 10px auto;

          cursor: pointer;

          svg {
            display: block;

            width: 24px;
            height: 24px;
          }

          &[data-focus-visible-added],
          &:hover {
            svg {
              filter: drop-shadow(0px 2px 4px var(--color-gray));
            }
          }
        }
      }
    }
  }

  .verticalFilterSortContainer {
    width: 100%;

    transition: width 150ms ease;

    &.shrink {
      width: calc(100% - 240px);
    }
  }
}

.popoverFilterSort {
  position: absolute;
  z-index: 11;

  top: 30px;
  right: 0;

  display: flex;

  flex-direction: column;

  width: 300px;
  max-height: calc(80vh - 80px);

  background-color: var(--color-dark-gray);

  border-radius: 8px;

  transition: opacity 300ms ease, transform 300ms ease;

  .body {
    padding-top: 24px;
  }

  &:global {
    &.enter,
    &.exit.exit-active {
      opacity: 0;

      transform: translateY(-20px);
    }

    &.enter.enter-active,
    &.exit {
      opacity: 1;

      transform: translateY(0);
    }
  }
}

.verticalFilterSort {
  position: fixed;
  z-index: 2;
  top: 64px;
  bottom: 0;
  width: var(--sidebar-right-width);
  min-height: 100%;

  background-color: var(--color-black2);

  transition: right 150ms ease, top 150ms ease;

  .body {
    padding: 50px 24px 24px;
  }

  .toggle {
    position: fixed;
    top: calc(64px + 32px);

    display: flex;

    align-items: center;
    justify-content: center;
    flex-direction: row;

    gap: 8px;

    padding: 12px 27px;

    font-size: var(--font-size-small);
    font-weight: 700;

    cursor: pointer;

    background-color: var(--color-black2);
    color: var(--color-white);

    border: 1px solid var(--color-dark-gray2);

    border-radius: 4px 0px 0px 4px;

    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.53);

    transition: right 150ms ease, top 150ms ease;

    svg {
      display: block;

      width: auto;
      height: 12px;

      &.closed {
        fill: var(--color-red);
      }

      &.opened {
        fill: var(--color-gray);
      }
    }

    &[data-focus-visible-added],
    &:hover {
      text-decoration: none;

      svg {
        &.closed {
          filter: drop-shadow(0 0 2px var(--color-red));
        }

        &.opened {
          filter: drop-shadow(0 0 2px var(--color-gray));
        }
      }
    }

    & .drawerFilterCount {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;

      font-size: 10px;
      font-weight: 700;

      background: var(--color-red);

      border-radius: 100px;
    }

    &.open {
      z-index: 2;
      top: calc(80px + 44px);
      padding: 14px 21px;

      border: 0;
    }
  }
}

@media (max-height: 720px) {
  .filterSort {
    .dropdown {
      .filterContainer {
        height: 400px;
      }
    }
  }
}

@media (--min-md) {
  .filterSort {
    .dropdown {
      .filterContainer {
        padding: 48px 24px 0 24px;

        .filterForm {
          .actions {
            .rollUp {
              margin-top: 24px;
            }
          }
        }
      }
    }

    .toggle {
      top: calc(80px + 16px);
    }

    .verticalFilterSort {
      top: 80px;
    }
  }
}
