[dir='rtl'] {
  .header {
    .container {
      .languageSelector {
        margin-left: 6px;
      }

      .logoWrapper {
        padding-left: 10px;
      }
    }
  }
}

[dir='ltr'] {
  .header {
    .container {
      .languageSelector {
        margin-right: 6px;
      }

      .logoWrapper {
        padding-right: 10px;
      }
    }
  }
}

.header {
  background-color: var(--color-black);

  :global(.layout-with-sidebar) & {
    .container {
      .logoWrapper {
        display: none;
      }
    }
  }

  .container {
    display: flex;

    align-items: center;
    justify-content: space-between;

    flex-direction: row;
    gap: 10px;
    height: 64px;

    &.games {
      .gamesContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

      }
    }

    &.notAuthenticated {
      display: flex;
      justify-content: flex-end;
    }

    &.forLanding {
      justify-content: space-between;
    }

    .buttonsContainer {
      display: flex;
    }

    .separator {
      flex: 1;

      min-width: 20px;
    }

    .logoWrapper {
      display: flex;

      justify-content: center;

      flex-direction: column;
    }

    .logo {
      display: block;

      height: 13px;
    }
  }
}

@media (--max-xs) {
  .header {
    .container {
      padding-left: 12px;

      &.games {
        padding-left: 20px;
      }
    }
    
    
  }
}

@media (--min-sm) {
  .header {
    .container {
      .logo {
        height: 20px;
      }
    }
  }
}

@media (--min-md) {
  .header {
    .container {
      gap: 0;
      height: 80px;
      padding-right: 10px;

      &.notAuthenticated {
        display: flex;
        justify-content: space-between;
      }

      .buttonsContainer {
        flex-wrap: wrap;
        gap: 20px;
      }

      .logo {
        height: 15px;
      }
    }
  }
}

@media (--min-lg) {
  .header {
    .container {
      gap: 20px;

      &.notAuthenticated {
        display: flex;
        justify-content: space-between;
      }

      &.forLanding {
        justify-content: space-between;
      }

      .languageSelector {
        display: block;
      }

      .logo {
        height: 20px;
      }

      .logoWrapper {
        margin-left: -24px;
      }
    }
  }
}
