[dir='rtl'] {
  .gamesBrowser {
    .content {
      .navigation {
        &.next {
          margin-right: 10px;
        }

        &.prev {
          margin-left: 10px;

          transform: rotate(180deg);
        }
      }

      .slider {
        .anchor {
          .poweredBy {
            right: 3px;
          }
        }
      }
    }
  }
}

[dir='ltr'] {
  .gamesBrowser {
    .content {
      .navigation {
        &.next {
          margin-left: 10px;

          transform: rotate(180deg);
        }

        &.prev {
          margin-right: 10px;
        }
      }

      .slider {
        .anchor {
          .poweredBy {
            left: 3px;
          }
        }
      }
    }
  }
}

.gamesBrowser {
  margin: 40px auto;

  .content {
    display: flex;

    align-items: center;

    flex-direction: row;

    margin-top: 12px;

    .slider {
      max-width: calc(100vw - 60px);

      padding: 18px 0;

      .slide {
        width: 140px;

        margin: 0 5px;

        border-radius: 8px;

        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.18);

        transition: transform 50ms ease;

        &:focus-within,
        &:hover {
          transform: scale(1.1);
        }

        .anchor {
          position: relative;

          display: block;

          font-size: var(--font-size-tiny);
          font-weight: 600;

          cursor: grab;

          border: 1px solid transparent;

          .poweredBy {
            position: absolute;
            top: 8px;

            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3px 8px;

            background: var(--color-black4);
            color: var(--color-gray3);

            border-radius: 24px;

            .gamerArenaLogo {
              width: 18px;
              height: 18px;
              margin-left: 4px;

              border-radius: 0;
            }
          }

          .comingSoon {
            position: absolute;
            bottom: -11px;
            left: 50%;

            display: flex;
            justify-content: center;

            width: 80px;

            padding: 4px 8px;

            background: var(--color-dark-gray2);
            color: var(--color-white);

            border-radius: 24px;

            transform: translateX(-50%);
          }

          img {
            display: block;
            width: 100%;

            border-radius: 4px;
          }
        }
      }
    }

    .navigation {
      display: none;

      flex: 0 0 auto;

      width: 40px;
      height: 40px;
      padding: 8px;

      cursor: pointer;
      visibility: visible;

      transition: visibility 150ms ease;

      svg {
        display: block;

        width: 100%;
        max-height: 100%;

        fill: var(--color-gray);

        transition: fill var(--transition-duration) ease;
      }

      &:not(.disabled)[data-focus-visible-added],
      &:not(.disabled):hover {
        svg {
          fill: var(--color-white);
        }
      }

      &.disabled {
        cursor: not-allowed;

        opacity: 0.1;
      }
    }
  }
}

@media (--min-sm) {
  .gamesBrowser {
    .content {
      .slider {
        max-width: none;
      }

      .navigation {
        display: block;
      }
    }
  }
}