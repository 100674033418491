.userPage {
  position: fixed;

  z-index: 9;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100vw;
  height: 100%;

  overflow: auto;

  background-color: var(--color-black);

  transition: transform 150ms ease;

  transform: translateY(0);

  &:global {
    &.enter,
    &.exit.exit-active {
      transform: translateY(100%);
    }

    &.enter.enter-active,
    &.exit {
      transform: translateY(0);
    }
  }

  .user {
    .tabs {
      margin: 30px 0;

      > * {
        display: grid;

        grid-gap: 24px;
      }
    }
  }
}

@media (--min-lg) {
  [dir='rtl'] {
    .userPage {
      .user {
        .tabs {
          .general {
            .col {
              &:nth-child(2) {
                margin-right: 24px;
              }
            }
          }
        }
      }
    }
  }

  [dir='ltr'] {
    .userPage {
      .user {
        .tabs {
          .general {
            .col {
              &:nth-child(2) {
                margin-left: 24px;
              }
            }
          }
        }
      }
    }
  }

  .userPage {
    .user {
      .tabs {
        .general {
          display: flex;

          .col {
            flex: 1;

            > :not(:first-child) {
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1810px) {
  .userPage {
    .user {
      width: 1810px;
    }
  }
}

@media only screen and (min-width: 2250px) {
  .userPage {
    .user {
      width: 2250px;
    }
  }
}
