.partners {
  margin: 0 auto;

  h3 {
    margin-bottom: 30px;

    font-size: var(--font-size-headline3);
    font-weight: 700;
    text-align: center;
  }

  .content {
    margin-top: 20px;

    .slider {
      max-width: calc(100vw - 60px);

      &.fullWidth {
        max-width: 100%;
      }

      .slide {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 265px;
        height: 140px;
        margin: 0 10px;

        background-color: var(--color-dark-gray2);

        border-radius: 16px;
      }
    }
  }

  .noPadding {
    width: 100%;
    padding: 0;
  }

  .partner {
    .icon {
      display: block;
      max-width: 100%;

      margin: 0 auto;

      &.papara {
        margin-top: 16px;
      }

      &.amazon {
        margin-top: 10px;
      }
    }
  }
}

@media (--min-sm) {
  .partners {
    .slider {
      max-width: none;
    }
  }
}

@media (--min-lg) {
  .partners {
    h3 {
      font-size: 48px;
    }
  }
}