.duelResultDeclaration {
  display: grid;

  align-items: center;
  justify-content: center;

  grid-template-columns: 1fr;
  grid-gap: 16px;

  padding: 24px 5px;

  background-color: var(--color-black3);

  border-radius: 8px;

  .muted {
    font-size: var(--font-size);
    font-weight: 600;
    text-align: center;

    color: var(--color-gray);
  }

  .buttonWrapper {
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;

    .cta {
      display: grid;

      align-items: center;
      justify-content: center;

      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 16px;
    }

    .notPlayed {
      margin-top: 16px;

      font-size: var(--font-size);
      font-weight: 600;
      text-align: center;

      cursor: pointer;

      color: var(--color-gray);

      border: none;

      &[data-focus-visible-added],
      &:hover {
        text-decoration: underline;
      }
    }

    .info {
      display: grid;

      grid-template-columns: auto auto;
      grid-gap: 8px;

      font-size: var(--font-size-small);
      font-weight: 600;

      color: var(--color-gray);

      svg {
        display: block;

        width: 18px;
        height: 18px;
      }
    }
  }
}

@media (--min-sm) {
  .duelResultDeclaration {
    padding: 24px 40px;

    .buttonWrapper {
      .cta {
        grid-template-columns: repeat(2, minmax(0px, 300px));
        grid-gap: 22px;
      }
    }
  }
}
